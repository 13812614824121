import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { FaPlus, FaEraser } from 'react-icons/fa';
import InformacionUsuario from './DetallesUsuario/informacionUsuario';
import Modal from 'react-modal';
import { AiOutlineCloseSquare } from 'react-icons/ai';
import { NotificationManager } from 'react-notifications';
import { RiSave3Fill } from 'react-icons/ri';
import Swal from 'sweetalert2';
import { getToken } from '../../utils/authService';
import axiosConfig from '../../utils/axiosConfig';
import { FaEdit } from 'react-icons/fa';
import { BsCardList } from 'react-icons/bs';
import ProgressBar from '../Progressbar/progress-bar.component';

import Select from 'react-select'

Modal.setAppElement('#root');

const estiloModal = {
   content: {
      top:'50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: '30%',
      height: '65%',
      transform: 'translate(-50%, -50%)',
   },
}

Modal.setAppElement('#root');
const estiloModal2 = {
  content: {
     top:'310px',
     left: '50%',
     right: 'auto',
     bottom: 'auto',
     width: '70%',
     transform: 'translate(-50%, -50%)',
  },
}

const DetallesUsuario = () => {
   const tableNames = ['Fecha inicio', 'Fecha final', 'Objetivo de submeta', 'Porcentaje completado', 'Acciones'];
   const token = getToken();
   const roles = jwt.decode(token);
   const { id: idFdetalle } = useParams();
   const [fdetalle, setFdetalle] = useState({});
   const [supervisores, setSupervisores] = useState([]);
   const [detalleMetas, setDetalleMetas] = useState([]);
   const [subgoals, setSubgoals] = useState([]);

console.log("roles", roles.role)

   //supervisores
   const [encargadoIntegracion, setEncargadoIntegracion] = useState([]);
   const [encargadoAdministrativo, setEncargadoAdministrativo] = useState([]);
   const [entrenadores, setEntrenadores] = useState([]);
   const [lideresPunto, setLideresPunto] = useState([]);
   const [encargadoCalidad, setEncargadoCalidad] = useState([]);

   //supervisores
   const [seleccionadoEncargadoIntegracion, setSeleccionadoEncargadoIntegracion] = useState([]);
   const [seleccionadoEncargadoAdministrativo, setSeleccionadoEncargadoAdministrativo] = useState([]);
   const [seleccionadoEntrenadores, setSeleccionadoEntrenadores] = useState([]);
   const [seleccionadoLideresPunto, setSeleccionadoLideresPunto] = useState([]);
   const [seleccionadoEncargadoCalidad, setSeleccionadoEncargadoCalidad] = useState([]);

   const [supervisoresAsignados, setSupervisoresAsignados] = useState([]);
   const [driveAsignado, setDriveAsignado] = useState([]);
   const [ openModal, setOpenModal ] = useState( false );
   const [ openModal2, setOpenModal2 ] = useState( false );
   const [ idSupervisor, setIdSupervisor] = useState('');

   const getFdetalles = async () => {
      const data = await axiosConfig({
         method: 'get',
         url: `/usuarios/${ idFdetalle }`,
      } );
      
      if (data) {
         setFdetalle(data);
      }
   };

   const getSupervisores = async () => {
      const data = await axiosConfig({
         method: 'get',
         url: `/usuarios/usuariosByRole/Supervisor de Operaciones Drive`,
         params: {
            limit: 9999999
         }
      } );
      
         if (data) {
            let option = [];
            for (let i = 0; i < data.rows.length; i++) {
               let objAux = {};
               objAux = {
                  value: data.rows[i].user_id,
                  label: data.rows[i].name
               }
               option.push(objAux);
            }
            console.log("supervisores: ", option)
            setSupervisores(option);
         }
   };

   const getSupervisoresAsignados = async () => {
      const data = await axiosConfig({
         method: 'get',
         url: `usuarios/coordinator/`+ idFdetalle,
      } );
      
      if (data) {
         setSupervisoresAsignados(data);
      }
   };

   const handlenModal = () => {
      setOpenModal( s => !s );
   }

   const handlenModal2 = () => {
      setOpenModal2( s => !s );
      setIdSupervisor('');
      getSupervisores();
      getSupervisoresAsignados();
   }

   const handleChangeIdSupervisor = (target) => {
      console.log("target", target)
      const idSupervisor = target.value;
      console.log("idSupervisor: ", idSupervisor)
      setIdSupervisor({ label: target.label, value: target.value });
   }

   const asignarSupervisor = async () => {
          
      await axiosConfig({
         method: 'POST',
         url: `usuarios/coordinator`,
         data: {
            id_coordinator: idFdetalle,
            id_supervisor: idSupervisor.value
         }
       });


      NotificationManager.success( "", "Supervisor asignado", 2500 )   
      getSupervisoresAsignados();
      setOpenModal(false)
   }

   const deleteSupervisor = async (idSupervisor) => {

      Swal.fire({
         title: '¿Estás seguro que quieres eliminarlo?',
         showDenyButton: true,
         confirmButtonText: 'Eliminar',
         denyButtonText: `Cancelar`,
       } ).then( ( result ) => {
         if( result.isConfirmed ){
            axiosConfig({
               method: 'DELETE',
               url: `usuarios/coordinator`,
               data: {
                  id_coordinator: idFdetalle,
                  id_supervisor: idSupervisor
               }
             }).then( async () => {
               NotificationManager.success( "", "Supervisor eliminado", 2500 )   
             await getSupervisoresAsignados();
           } ).catch( () => NotificationManager.success( "Algo salió mal, contacte a un administrador.", "Oops", 2500 ) )
         } else {
           if( result.isDenied ) {
             
           }
         }
       } )
   }

   //Supervisores
   const guardarAsignacionSupervisor = async (objAsignaciones) => {
      console.log("objAsignaciones: ", objAsignaciones)
      Swal.fire({
         title: '¿Estás seguro que quieres guardar?',
         showDenyButton: true,
         confirmButtonText: 'Guardar',
         denyButtonText: `Cancelar`,
       } ).then( ( result ) => {
         if( result.isConfirmed ){
            axiosConfig({
               method: 'POST',
               url: `usuarios/supervisor`,
               data: objAsignaciones
             }).then( async () => {
               NotificationManager.success( "", "Guardado con éxito.", 2500 )   
               await getAsignacionesSupervisor();
           } ).catch( () => NotificationManager.success( "Algo salió mal, contacte a un administrador.", "Oops", 2500 ) )
         } else {
           if( result.isDenied ) {
             
           }
         }
       } )
   }

   //Supervisores
   const getAsignacionesSupervisor = async () => {
      const data = await axiosConfig({
         method: 'get',
         url: `usuarios/supervisor/`+idFdetalle,
      } );
      
      if (data) {
         setDriveAsignado(data);

         data.forEach(driveAsig => {
            driveAsig.workers.forEach(worker => {
               if(worker.rol_name === "Encargado Administrativo"){
                  setSeleccionadoEncargadoAdministrativo({ label: worker.name, value: worker.user_id })
               }
               if(worker.rol_name === "Entrenador"){
                  setSeleccionadoEntrenadores({ label: worker.name, value: worker.user_id })
               }
               if(worker.rol_name === "Lider de Punto"){
                  setSeleccionadoLideresPunto({ label: worker.name, value: worker.user_id })
               }
               if(worker.rol_name === "Encargado de integración"){
                  setSeleccionadoEncargadoIntegracion({ label: worker.name, value: worker.user_id })
               }
               if(worker.rol_name === "Encargado de Calidad"){
                  setSeleccionadoEncargadoCalidad({ label: worker.name, value: worker.user_id })
               }
            });
         });
      }
   };

   //Supervisores
   const getEncargadoIntegracion = async () => {
      const data = await axiosConfig({
         method: 'get',
         url: `/usuarios/usuariosByRole/Encargado de integración?active=true`,
      } );
      
      if (data) {
         let option = [];
         for (let i = 0; i < data.rows.length; i++) {
            let objAux = {};
            objAux = {
               value: data.rows[i].user_id,
               label: data.rows[i].name
            }
            option.push(objAux);
         }
         setEncargadoIntegracion(option);
      }
   };

   //Supervisores
   const getEncargadoAdministrativo = async () => {
      const data = await axiosConfig({
         method: 'get',
         url: `/usuarios/usuariosByRole/Encargado Administrativo?active=true`,
      } );
      
      if (data) {
         let option = [];
         for (let i = 0; i < data.rows.length; i++) {
            let objAux = {};
            objAux = {
               value: data.rows[i].user_id,
               label: data.rows[i].name
            }
            option.push(objAux);
         }
         setEncargadoAdministrativo(option);
      }
   };

   //Supervisores
   const getEntrenadores = async () => {
      const data = await axiosConfig({
         method: 'get',
         url: `/usuarios/usuariosByRole/Entrenador?active=true`,
      } );
      
      if (data) {
         let option = [];
         for (let i = 0; i < data.rows.length; i++) {
            let objAux = {};
            objAux = {
               value: data.rows[i].user_id,
               label: data.rows[i].name
            }
            option.push(objAux);
         }
         setEntrenadores(data.rows);
      }
   };

   //Supervisores
   const getLideresPunto = async () => {
      const data = await axiosConfig({
         method: 'get',
         url: `/usuarios/usuariosByRole/Lider de Punto?active=true`,
      } );
      
      if (data) {
         let option = [];
         for (let i = 0; i < data.rows.length; i++) {
            let objAux = {};
            objAux = {
               value: data.rows[i].user_id,
               label: data.rows[i].name
            }
            option.push(objAux);
         }
         setLideresPunto(data.rows);
      }
   };

   //Supervisores
   const getEncargadoCalidad = async () => {
      const data = await axiosConfig({
         method: 'get',
         url: `/usuarios/usuariosByRole/Encargado de Calidad?active=true`,
      } );
      
      if (data) {
         let option = [];
         for (let i = 0; i < data.rows.length; i++) {
            let objAux = {};
            objAux = {
               value: data.rows[i].user_id,
               label: data.rows[i].name
            }
            option.push(objAux);
         }
         setEncargadoCalidad(data.rows);
      }
   };

   const handleChangeValue = (input, indexDrive, indexWorker, rol_name) => {
      console.log("value: ", input)
      const auxDrive = [...driveAsignado];

      //auxDrive[indexDrive].workers[indexWorker].user_id = (Number(input.value) === 0 ? null : Number(input.vale));
      auxDrive[indexDrive].workers[indexWorker].user_id = input.value;

      if(rol_name === "Encargado Administrativo"){
         setSeleccionadoEncargadoAdministrativo({ label: input.label, value: input.value })
      }
      if(rol_name === "Entrenador"){
         setSeleccionadoEntrenadores({ label: input.label, value: input.value })
      }
      if(rol_name === "Lider de Punto"){
         setSeleccionadoLideresPunto({ label: input.label, value: input.value })
      }
      if(rol_name === "Encargado de integración"){
         setSeleccionadoEncargadoIntegracion({ label: input.label, value: input.value })
      }
      if(rol_name === "Encargado de Calidad"){
         setSeleccionadoEncargadoCalidad({ label: input.label, value: input.value })
      }
      
      setDriveAsignado(auxDrive);
   }

   useEffect(() => {
      getFdetalles();
      getSupervisores();
      getSupervisoresAsignados();
      getAsignacionesSupervisor();
      getDetalleMeta()

      //Supervisores
      getEncargadoIntegracion();
      getEncargadoAdministrativo();
      getEntrenadores();
      getLideresPunto();
      getEncargadoCalidad();
      // eslint-disable-next-line
   }, [ idFdetalle ] );


   const handleModal = (subgoals) => {
      setOpenModal( s => !s );
      getDetalleMeta();
  
      setSubgoals(subgoals);
    }

    const getDetalleMeta = useCallback(  async () => {
      const rows = await axiosConfig({
        method: 'get',
        url: `/goals/user`,
        params: {
          user_id: idFdetalle
        },
      });
      
      if (rows) {
          setDetalleMetas(rows.rows);
      }
    } , [idFdetalle] );

    const handleChangeSubgoal = (evt, id) => {
      const { name, value } = evt.target;
  
      const newItems = subgoals.map(item => {
        if (item.id_sub_goal !== id) {
          return { ...item };
        }
  
        return { ...item, minimum_percentage: value};
      });
  
      setSubgoals(newItems);
    };

    const handleBandera = (evt, id) => {
  
      const newItems = subgoals.map(item => {
        if (item.id_sub_goal !== id) {
          return { ...item };
        }
  
        return { ...item, active: !item.active};
        
      });
      setSubgoals(newItems);
    }
    
    const guardarCambioRow = async (subgoal) => {
      Swal.fire({
         title: '¿Estás seguro que quieres guardar?',
         showDenyButton: true,
         confirmButtonText: 'Guardar',
         denyButtonText: `Cancelar`,
       } ).then( ( result ) => {
         if( result.isConfirmed ){
            axiosConfig({
               method: 'PUT',
               url: `/goals/subgoal/`+subgoal.id_sub_goal,
               data: subgoal
             }).then( async () => {
               NotificationManager.success( "", "Guardado con éxito.", 2500 );
               setOpenModal( s => !s );   
               await getDetalleMeta();
           } ).catch( () => NotificationManager.success( "Algo salió mal, contacte a un administrador.", "Oops", 2500 ) )
         } else {
           if( result.isDenied ) {
             
           }
         }
       } )
   }

      return (
         <div className='py-6'>
            <InformacionUsuario />

            <div className='Login w-full bg-white shadow rounded m-6'>
               <div className='rojodiv w-full h-4 rounded-t'></div>
               <div className='flex flex-row border-b-2 titulo pl-6 pt-3 pb-3 text-xl'>
                  Metas
               </div>
               
               <div className='p-4'>
                  <div style={{ overflow: "auto" }}>
                     <table className='content-table w-full'>
                     <thead>
                        <tr>
                        <th>Nombre</th>
                        <th>Objetivo de la meta</th>
                        <th>Porcentaje avanzado</th>
                        <th>
                           Opciones
                        </th>
                        </tr>
                     </thead>
                     <tbody>
                     { detalleMetas && detalleMetas.map((usuario, index) => {
                           return (
                           <tr key={index}>
                                 <td>{usuario.name}</td>
                                 <td>{usuario.contadorCompletado +'/'+ usuario.goal}</td>
                                 <td><ProgressBar bgcolor={'#81BC00'} completed={(((usuario.contadorCompletado/usuario.goal)*100) > 100) ? 100 : ((usuario.contadorCompletado/usuario.goal)*100).toFixed(1) } /></td>
                                 <td>
                                 <div className="flex flex-row justify-center">
                                       <button className='flex flex-row mx-3 botonazuldetalles' onClick={ () => handleModal(usuario.sub_goals) }>
                                       <div className='pt-1 px-1'>
                                             <BsCardList />
                                       </div>
                                       <div>Ver submetas</div>
                                       </button>                     
                                 </div>
                                 </td>
                           </tr>
                           );
                        }) }
                     </tbody>
                     </table>
                  </div>
               </div>  
                        
                     
            </div>

            {(fdetalle.rol === "Supervisor de Operaciones Drive") && <div className='flex flex-row justify-between w-full p-6'>
               <div className='titulo  text-3xl'>Drives asignados</div>
            </div>}

            <div className='flex flex-row w-full grid grid-cols-3'>
            {(fdetalle.rol === "Supervisor de Operaciones Drive") && driveAsignado && driveAsignado.map((driveAsig, i) => {
               return (
                     <div className='flex flex-row pl-6 w-full'>
                        <div className='Login w-full bg-white shadow rounded mt-5'>
                           <div className='rojodiv w-full h-4 rounded-t'></div>
                           <div className='flex flex-row border-b-2 titulo pl-6 pt-3 pb-3 text-xl'>
                             {driveAsig.nombre}
                           </div>
                           <div className='p-4'>
                              <div style={{ overflow: "auto" }}>
                                 {driveAsig.workers.map((worker, index) => {
                                    return (
                                       <div className='pt-3'>
                                          <div>{worker.rol_name}</div>

                                          {<Select 
                                             id={worker.rol_id}  
                                             className='rounded w-full'
                                             noOptionsMessage={() => 'No hay trabajadores.'}
                                             value={(worker.rol_name === "Entrenador") ? seleccionadoEntrenadores : worker.rol_name === "Lider de Punto" ? seleccionadoLideresPunto : worker.rol_name === "Encargado Administrativo" ? seleccionadoEncargadoAdministrativo : worker.rol_name === "Encargado de integración" ? seleccionadoEncargadoIntegracion : worker.rol_name === "Encargado de Calidad" ? seleccionadoEncargadoCalidad : []}
                                             onChange={(value) => handleChangeValue( value, i, index, worker.rol_name )} 
                                             disabled={(!roles.role === "Supervisor de Operaciones Drive" || !roles.role === "Administrador" || !roles.role === "Coordinador")} 
                                             options={(worker.rol_name === "Entrenador") ? entrenadores : worker.rol_name === "Lider de Punto" ? lideresPunto : worker.rol_name === "Encargado Administrativo" ? encargadoAdministrativo : worker.rol_name === "Encargado de integración" ? encargadoIntegracion : worker.rol_name === "Encargado de Calidad" ? encargadoCalidad : [] }/>}
                                       </div>
                                    )
                                 })}
                                 <hr  className='mt-5'/>
                                 {(roles.role === "Supervisor de Operaciones Drive" || roles.role === "Administrador" || roles.role === "Coordinador") && <div className='flex flex-row mt-5'>
                                    <button type='button' className='botonverde m-auto px-7 rounded shadow' onClick={() => guardarAsignacionSupervisor(driveAsig)} >
                                    <div className='pt-1 px-2 flex flex-row '>
                                       <span className=' px-1'>
                                          <RiSave3Fill className=' mx-auto text-2xl	' />
                                       </span>
                                       Guardar
                                    </div>
                                    </button>
                                 </div>}
                              </div>
                           </div>  
                        </div>
                     </div>
               );
            }) }
            </div>
            
            {(fdetalle.rol === "Coordinador") && <div className='flex flex-row justify-between w-full pt-6'>
               <div className='titulo pb-6 text-3xl'></div>
               <button className='botonverde py-4 flex flex-row w-50 h-10 justify-items-center rounded-sm shadow' onClick={ handlenModal2 }>
                  <div className='pt-1 px-2'>
                     <FaPlus />
                  </div>
                  <div>Asignar usuario</div>
               </button>
            </div>}
            {(fdetalle.rol === "Coordinador") && <div className='flex flex-row pl-6'>
               <div className='Login w-full bg-white shadow rounded mt-5'>
                  <div className='rojodiv w-full h-4 rounded-t'></div>
                  <div className='flex flex-row border-b-2 titulo pl-6 pt-3 pb-3 text-xl'>
                     Supervisores asignados
                  </div>
                  
                  <div className='p-4'>
                     <div style={{ overflow: "auto" }}>
                        <table className='content-table w-full'>
                        <thead>
                           {supervisoresAsignados.length > 0 && <tr>
                              <th>Nombre</th>
                              <th>Opciones</th>
                           </tr>}
                        </thead>
                        <tbody>
                        { supervisoresAsignados && supervisoresAsignados.map((supervisorAsig, index) => {
                           return (
                              <tr>
                                 <td>{supervisorAsig.name}</td>
                                 {(roles.role === "Administrador" || roles.role === "Coordinador") && <td>
                                 <div className="flex flex-row justify-center">
                                    <button className='flex flex-row mx-3 botonrojodetalles' onClick={() => deleteSupervisor(supervisorAsig.user_id) }>
                                    <div className='pt-1 px-1'>
                                       <FaEraser />
                                    </div>
                                    <div>Eliminar</div>
                                    </button>                     
                                 </div>
                                 </td>}
                              </tr>
                           );
                        }) }
                        {
                           supervisoresAsignados.length === 0 &&
                           <tr> NO HAY SUPERVISORES ASIGNADOS. </tr>
                        }
                        </tbody>
                        </table>
                     </div>
                  </div>  
               </div>
            </div>}

            <Modal  isOpen={ openModal2 } contentdiv='Usuario Modal' style={estiloModal} onRequestClose={ () => handlenModal2() } >
                     
               <div className='flex flex-row justify-between border-b-2 pb-2'>
                  <div className='titulo text-xl'>
                     Asignación de supervisor
                  </div>
      
                  <button className='rojoletra' onClick={ () => setOpenModal2( false ) } >
                  <AiOutlineCloseSquare size={28} />
                  </button>
               </div>
      
               <div className='w-1/12'  >
                  <p className='my-2' >
                     Supervisor
                  </p>
               </div>
                  
               <div className='w-11/12 flex flex-row'>
                  <Select 
                     className='rounded w-full'
                     noOptionsMessage={() => 'No hay trabajadores.'}
                     value={idSupervisor}
                     onChange={(value) => handleChangeIdSupervisor(value)} 
                     options={supervisores.filter( ( item ) => {
                        const flat = supervisoresAsignados.find( ( elem ) => elem.user_id === item.value )
                        return !flat
                     } )}
                  />
               </div>
      
               <hr  className='mt-5'/>
      
               <div className='flex flex-row mt-5'>
                  <button type='button' className='botonverde m-auto px-7 rounded shadow' onClick={ asignarSupervisor } >
                  <div className='pt-1 px-2 flex flex-row '>
                     <span className=' px-1'>
                        <RiSave3Fill className=' mx-auto text-2xl	' />
                     </span>
                     Guardar
                  </div>
                  </button>
               </div>
      
               </Modal>


               {<Modal  isOpen={ openModal } contentdiv='Usuario Modal' style={estiloModal2} onRequestClose={ () => handleModal() }>
            
            <div className='flex flex-row justify-between border-b-2 pb-2'>
               <div className='titulo text-xl'>
                  Detalle de submeta
               </div>

               <button className='rojoletra' onClick={ () => setOpenModal( false ) }>
               <AiOutlineCloseSquare size={28} />
               </button>
            </div>
            
            <div className='w-full'>
              <div className='p-4'>
                <div style={{ overflow: "auto" }}>
                  <table className='content-table w-full'>
                    <thead>
                      <tr>
                        {tableNames.map((name) => {
                          return <th key={name}>{name}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                    { subgoals && subgoals.map((subgoal, index) => {
                      return (
                        <tr key={index}>
                            <td>{subgoal.fechaInicio}</td>
                            <td>{subgoal.fechaFinal}</td>
                            {/*Aquí usé el active como bandera porque se me hizo más fácil manejarlo así. */}
                            {subgoal.active && <td>{subgoal.contadorCompletado +'/'+subgoal.minimum_percentage}</td>}
                            {!subgoal.active && <td>
                                <input 
                                  type="number"
                                  min="1"
                                  value={subgoal.minimum_percentage}
                                  onChange={evt => handleChangeSubgoal(evt, subgoal.id_sub_goal)}
                                  className='w-1/4 pl-1 border border-gray-300 rounded	 pt-1 '
                                />
                              </td>}
                            {<td><ProgressBar bgcolor={'#81BC00'} completed={((subgoal.contadorCompletado/subgoal.minimum_percentage)*100) > 100 ? 100 : ((subgoal.contadorCompletado/subgoal.minimum_percentage)*100).toFixed(1)} /></td>}
                            {subgoal.active && (
                              <td>
                                <div className="flex flex-row justify-center">
                                  <button className={`flex flex-row mx-3 ${roles.role === "Administrador" ? 'botonverdeeditar' : 'botongriseditar'}`} onClick={evt => handleBandera(evt, subgoal.id_sub_goal)} disabled={roles.role !== "Administrador"}>
                                      <div className='pt-1 px-1'>
                                      <FaEdit />
                                      </div>
                                      <div>Editar</div>
                                  </button>
                                </div>
                              </td>
                              
                            )}
                            {!subgoal.active && (
                              <td>
                                <div className="flex flex-row justify-center">
                                  <button className='flex flex-row mx-3 botonverdeeditar' onClick={() => guardarCambioRow(subgoal)}>
                                    <div className='pt-1 px-1'>
                                      <RiSave3Fill className=' mx-auto text-2xl	' />
                                    </div>
                                    <div>Guardar</div>
                                  </button>
                                </div>
                              </td>
                              
                            )}
                        </tr>
                      );
                    }) }
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
               
            

         </Modal>}


         </div>



         
      );

};

export default DetallesUsuario;
