import React, { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosConfig from '../../utils/axiosConfig';
import { BsCardList, BsArrowLeftShort, BsArrowRightShort, } from 'react-icons/bs';

import ProgressBar from '../Progressbar/progress-bar.component';

const Miavance = () => {
  const [totalRows, setTotalRows] = useState();
  const [estadisticas, setEstadisticas] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [estadisticaPerPage, setEstadisticaPerPage] = useState(6);
  const [pageNumbers, setPageNumbers] = useState([]);


  const getEstadisticas = useCallback( async () => {
    const { count, rows } = await axiosConfig({
      method: 'get',
      url: `/dashboard`,
      params: {
        limit: estadisticaPerPage,
        page: currentPage,
      },
    });

    if (rows) {
      if (count > 0) {
        setTotalRows(count);
      }
      setEstadisticas(rows);
    }
  }, [estadisticaPerPage, currentPage] );

    useEffect(() => {
      
      const auxPageNumbers = [];
      for (let i = 1; i <= Math.ceil(totalRows / estadisticaPerPage); i++) {
        auxPageNumbers.push(i);
      }
      setPageNumbers(auxPageNumbers);
    }, [totalRows]);

  useEffect(() => {
    setCurrentPage(1);
  }, [estadisticaPerPage]);

  useEffect(() => {
    getEstadisticas();
  }, [getEstadisticas]);

  return (
    <div>
      <div>
        <div className='flex flex-row justify-between pb-2'></div>
      </div>
      <div className='pl-6 grid justify-items-center ' >
        <div className='Login w-full bg-white shadow rounded' style={ {minHeight: '540px'} }>
          <div className='verde w-full h-4 rounded-t'></div>
          {/* ---------------------------------------------------------------------- */}

          <div className='w-full'>
            <div className='flex flex-row border-b-2 pl-6 pt-3 pb-3 '>
            
              <div className='titulo text-xl'> Estadísticas de metas </div>
            </div>

            <div className='flex flex-wrap w-full'>
                {estadisticas && estadisticas.map((stat, index) => {
                  return (
                    <div class="w-1/2 p-3">
                      <div class="border-r border-b border-l border-t border-gray-400 bg-white w-full p-2">
                          <div class="grid justify-items-center">
                            <div class="text-gray-900 font-bold text-l"> {stat.name} </div>
                          </div>
                          <div class="w-full pr-2 pl-2">
                            <ProgressBar bgcolor={'#81BC00'} completed={(((stat.contadorCompletado/stat.goal)*100) > 100) ? 100 : ((stat.contadorCompletado/stat.goal)*100).toFixed(1) } />
                          </div>
                          <div class="grid justify-items-center pb-2">
                            <button
                              className='bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white px-4 border border-blue-500 hover:border-transparent rounded w-25 h-7 rounded shadow'
                              >
                                <Link
                                  to={`/inicio/metas/metadetalle/${ stat.id_goal_config }/${ stat.month }/${ stat.year }`}
                                  className='w-16'
                                >
                                  <div className='flex flex-row'>
                                    <div className='pt-1 px-1'>
                                      <BsCardList />
                                    </div>
                                    <div>Detalles</div>
                                  </div>
                                </Link>
                            </button>
                          </div>
                      </div>
                    </div>
                  );
              }) }
              
              
            </div>
            <div className='flex flex-row-reverse mt-3 pb-5 pr-5'>
              {/* Rows per page select */}

              {/* Page navigator */}
              <div className='inline-flex'>
                <button
                  onClick={() => {
                    setCurrentPage((prev) => {
                      return prev - 1;
                    });
                  }}
                  disabled={currentPage === 1 ? 'disabled' : ''}
                  className={`btn-primary bg-blue-500 text-white py-1 px-2
								rounded-l focus:outline-none text-xl ${
                  currentPage === 1
                    ? 'opacity-50 cursor-not-allowed'
                    : 'opacity-75 hover:opacity-100'
                }`}
                >
                  <BsArrowLeftShort />
                </button>
                <select
                  value={currentPage}
                  onChange={(event) =>
                    setCurrentPage(parseInt(event.target.value))
                  }
                  className='btn-primary bg-blue-500 text-white py-1 px-2 focus:outline-none'
                >
                  {pageNumbers.map((p) => {
                    return (
                      <option key={p} value={p}>
                        {p}
                      </option>
                    );
                  })}
                </select>
                <button
                  onClick={() => {
                    setCurrentPage((prev) => {
                      return prev + 1;
                    });
                  }}
                  disabled={
                    currentPage === pageNumbers.length ? 'disabled' : ''
                  }
                  className={`btn-primary bg-blue-500 text-white py-1 px-2 rounded-r focus:outline-none text-xl ${
                    currentPage === pageNumbers.length
                      ? 'opacity-50 cursor-not-allowed'
                      : 'opacity-75 hover:opacity-100'
                  }`}
                >
                  <BsArrowRightShort />
                </button>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default function MiavanceTabs() {
  return (
    <>
      <Miavance color='emerald' />
    </>
  );
}
