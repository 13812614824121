import React from 'react';
import Avancemensual from './avanceMensual';
import Notificaciones from './notificaciones';

const Dashboard = () => {
  return (
    <div className='flex flex-row '>
      <div className=' w-3/5 h-40'>
        {/*<Miavance />*/}
        <div className='pb-8 pt-10'>
          <Avancemensual />
        </div>
      </div>
      <div className='w-5/12 h-40'>
        <Notificaciones />
      </div>
    </div>
  );
};

export default Dashboard;
