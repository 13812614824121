import React, { useState } from 'react';

import jwt from 'jsonwebtoken';
import { getToken } from '../utils/authService';

import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

import '../style/tailwind.css';

import { Panel } from './panel.routes';

import Topbar from '../components/Topbar';
import SideMenu from '../components/SideBar/SideMenu';
import SideMenuA from '../components/SideBar/SideMenuA';
import SideMenuSC from '../components/SideBar/SideMenuSC';


const Layout = () => {
  const [inactive, setInactive] = useState(false);

  const token = getToken();

  const roles = jwt.decode(token);

  if (roles.role === 'Administrador') {
    return (
      <div style={{ maxWidth: "80vw" }}>

        <SideMenuA onCollapse={(inactive) => setInactive(inactive)} />
        {/* <div className=''>
            {/* Aqui Huguez wawa
         </div> 
         */}
        <div className={`container ${inactive ? 'inactive' : ''}`}>
          <div className='h-14 '>
            <Topbar inactive={inactive} />
          </div>
          <div className={`main ${inactive ? 'inactive' : ''}`}>
            <Panel />
          </div>
        </div>

        <NotificationContainer />
      </div>
    );
  } /*else if (roles.role === 'Encargado Administrativo'
    || roles.role === 'Administrador'
    || roles.role === 'Supervisor de Operaciones Drive'
    || roles.role === 'Coordinador'
    || roles.role === 'Entrenador'
    || roles.role === 'Encargado de integración'
    || roles.role === 'Encargado de Calidad'
  ) {
    return (
      <div>
        <div className='max-w-min'>
          <div className='bg-black'>
            
            <SideMenuSC
              onCollapse={(inactive) => {
                console.log("tako");
                setInactive(inactive);
              }}
            />
          </div>
          <div className={`container ${inactive ? 'inactive' : ''}`}>
            <div className='h-14 w-screen '>
              <Topbar />
            </div>
            <div className='w-full h-full'>
              <Panel />
            </div>
          </div>
        </div>

        <NotificationContainer />
      </div>
    );
  } */else {
    return (
      <div>
        <div className='max-w-min'>
          <div className='bg-black'>
            <SideMenu
              onCollapse={(inactive) => {
                console.log("q1");
                setInactive(inactive);
              }}
            />
          </div>
          <div className={`container ${inactive ? 'inactive' : ''}`}>
            <div className='h-14 w-screen '>
              <Topbar />
            </div>
            <div className='w-full h-full'>
              <Panel />
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>
    );
  }
};

export default Layout;
