import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

// import { decode } from 'jsonwebtoken';

import { MdArrowBack } from 'react-icons/md';
import { IoMdArrowRoundForward } from 'react-icons/io';

// import { getToken } from '../../utils/authService';

import axiosConfig from '../../utils/axiosConfig';


const Visitaformularios = () => {
   
   const [ forms, setForms ] = useState( [] )
   const history = useHistory()

   const getDrive = useCallback(  async () => {
      const { rows } = await axiosConfig({
        method: 'GET',
        url: `forms/formsByUser`
      } );

      if( rows && rows.length > 0 ){
         setForms( rows )
      }
   }, [] )


   useEffect( () => {
      getDrive()

   }, [ getDrive ] )
   
   return (
      <div className=' grid justify-items-center'>
         <div className='Login w-11/12 bg-white shadow rounded'>
            <div className='rojodiv w-full h-6 rounded-t'></div>

            <Link to='/inicio/visitas' className='flex flex-row border-b-2 titulo pl-6 pt-5 pb-3 text-xl'>
               <div className='pt-1 pr-1'>
               <MdArrowBack size={24} />
               </div>
               Formularios de visita
            </Link>

            <div className='flex flex-col pt-4 pb-8 px-4'>
               <div className='pb-4'>Lista de formularios</div>
               {
                  forms.map( ( f, index ) => (
                     <button onClick={ () => history.push( `/inicio/visitas/formularios/${ f.Form.id_form }` ) } key={index} className='botonazulborde my-2 flex flex-row justify-between'>     
                        { f.Form.name }
                        <div className='azul'>
                           <IoMdArrowRoundForward size={24} />
                        </div>
                     </button>
                  ) )
               }
            </div>
         </div>
      </div>
   );
};

export default Visitaformularios;
