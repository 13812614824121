import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Usuariostabla from '../components/Usuario/usuariostabla';
import InformacionVacaciones from '../components/Usuario/DetallesUsuario/informacionVacaciones';
import DetallesUsuario from './../components/Usuario/Destallesusuario';
import DetallesPA from './../components/Usuario/DetallesPA';
import VisitaformulariosD from './../components/Visitas/visitaformularioD';

const UsuarioRoute = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${url}/`} component={Usuariostabla} />
      <Route path={`${url}/infovacaciones`} component={InformacionVacaciones} />
      <Route
        path={`${url}/detallesPersonasAsiganadas`}
        component={DetallesPA}
      />
      <Route
        path={`${url}/rformulario/:id/:idmeta`}
        component={VisitaformulariosD}
      />

      <Route path={`${url}/detalles/:id`} component={DetallesUsuario} />
    </Switch>
  );
};

export default UsuarioRoute;
