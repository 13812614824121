import React, { useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

import Swal from 'sweetalert2';
import axiosConfig from '../../utils/axiosConfig';
import { DateTime } from 'luxon';
import { BsCardList } from 'react-icons/bs';

const moment = require('moment');

const VacacionesDetalles = () => {
  const { id: idVacation } = useParams();
  const history = useHistory();
  const [vdetalle, setVdetalle] = useState({});
  const [historialVacaciones, setHistorialVacaciones] = useState([]);

  const getFdetalles = async () => {
    const data = await axiosConfig({
      method: 'get',
      url: `vacations/${idVacation}`,
    });

    if (data) {
      setVdetalle(data);
    }
  };

  const getHistorialVacaciones = async () => {
    const data = await axiosConfig({
      method: 'get',
      url: `vacations?user_id=1`,

    });

    if (data) {
      setHistorialVacaciones(data.rows);
    }
  };

  useEffect(() => {
    getFdetalles();
    getHistorialVacaciones();

    // eslint-disable-next-line
  }, [idVacation]);

  function RechazarV(idVacation) {
    Swal.fire({
      icon: 'warning',
      title: 'Aviso',
      text: 'Está a punto de rechazar estas vacaciones esto no puede cambiarse. ¿Rechazar vacaciones? ',
      showDenyButton: true,

      denyButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',

      confirmButtonColor: '#2F80ED',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axiosConfig({
          method: 'PUT',
          url: `vacations/`+idVacation,
          data: {
            id_status: 3
          }
        }).then( async () => {
          getHistorialVacaciones();
          Swal.fire('Rechazada', '', 'error');
          history.push('/inicio/vacaciones')
      } )
        
      } else if (result.isDenied) {

      }
    });
  }

  function AceptarV(idVacation) {
    Swal.fire({
      icon: 'warning',
      title: 'Aviso',
      text: 'Está a punto de aceptar estas vacaciones esto no puede cambiarse. ¿Confirmar vacaciones? ',
      showDenyButton: true,

      denyButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',

      confirmButtonColor: '#2F80ED',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axiosConfig({
          method: 'PUT',
          url: `vacations/`+idVacation,
          data: {
            id_status: 2
          }
        }).then( async () => {
          getHistorialVacaciones();
          Swal.fire('Aceptado', '', 'success');
          history.push('/inicio/vacaciones')
      } )
      } else if (result.isDenied) {
        
      }
    });
  }
  return (
    <div>
      <div className=' grid justify-items-center'>
        <div className='Login w-11/12 bg-white shadow rounded'>
          <div className='rojodiv w-full h-6 rounded-t'></div>
          <div className='flex flex-row border-b-2 titulo pl-6 pt-5 pb-3 text-xl'>
            <Link to={`/inicio/vacaciones`}>
              <div className='pr-4'>
                <MdArrowBack size={24} />
              </div>
            </Link>
            Detalle de vacaciones
          </div>

          <div className='tablamenu p-4'>
            <div className='flex flex-row'>
              <div className='w-1/5'>
                <div className='font-black'>Nombre</div>
                <div>{vdetalle.user ? vdetalle.user.name : ''}</div>
              </div>
              <div className='w-1/5'>
                <div className='font-black'>Rol</div>
                <div>{vdetalle.user ? vdetalle.user.rol : ''}</div>
              </div>
              <div className='w-1/5'>
                <div className='font-black'>Email</div>
                <div>{vdetalle.user ? vdetalle.user.email : ''}</div>
              </div>
              {vdetalle.status && vdetalle.status.id_status === 1 && <div className='w-1/5'>
                <button
                className='botonrojo  w-44 h-10 rounded shadow'
                onClick={() => RechazarV(vdetalle.id_vacation)}
                >
                  Rechazar
                </button>
              </div>}
              {vdetalle.status && vdetalle.status.id_status === 1 && <div className='w-1/5'>
                  <button
                  className='botonverde  w-44 h-10 rounded shadow'
                  onClick={() => AceptarV(vdetalle.id_vacation)}
                  >
                    Aceptar Vacaciones
                  </button>
              </div>}
            </div>
            <div>
              <div className='font-black pt-4'>Dias solicitados</div>
              {moment(vdetalle.start_date).utc().format('YYYY/MM/DD')}     al     {moment(vdetalle.final_date).utc().format('YYYY/MM/DD')}
            </div>
          </div>
        </div>
      </div>
      <div className='Login w-11/12 bg-white shadow rounded mt-5 ml-11'>
        <div className='rojodiv w-full h-4 rounded-t'></div>
        <div className='flex flex-row border-b-2 titulo pl-6 pt-3 pb-3 text-xl'>
          Historial de vacaciones
        </div>
        
        <div className='p-4'>
          <div style={{ overflow: "auto" }}>
            <table className='content-table w-full'>
              <thead>
                <tr>
                 <th>Fecha inicio</th>
                 <th>Fecha fin</th>
                 <th>Estatus</th>
                </tr>
              </thead>
              <tbody>
              { historialVacaciones && historialVacaciones.map((historial, index) => {
                  return (
                    <tr key={index}>
                        <td>{moment(historial.start_date).utc().format('YYYY/MM/DD')}</td>
                        <td>{moment(historial.final_date).utc().format('YYYY/MM/DD')}</td>
                        <td>{historial.status.name}</td>
                    </tr>
                  );
                }) }
              </tbody>
            </table>
          </div>
        </div>  
      </div>
      
    </div>
  );
};

export default VacacionesDetalles;
