import React, { useCallback, useEffect, useState } from 'react';
import { FaBell, FaCheckDouble } from 'react-icons/fa';
import axiosConfig from '../../utils/axiosConfig';
import { BsCardList, BsArrowLeftShort, BsArrowRightShort, } from 'react-icons/bs';

const Notificaciones = () => {

  const [notificaciones, setNotificaciones] = useState([]);
  const [totalRows, setTotalRows] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [notificacionesPerPage, setNotificacionesPerPage] = useState(5);

  useEffect(() => {
    setCurrentPage(1);
  }, [notificacionesPerPage]);


  const getNotificaciones = useCallback( async () => {
    const {count, rows} = await axiosConfig({
      method: 'get',
      url: `dashboard/notifications`,
      params: {
        limit: notificacionesPerPage,
        page: currentPage,
      },
    });

    if (rows) {
      if (count > 0) {
        setTotalRows(count);
      }
      setNotificaciones(rows);
    }
  }, [notificacionesPerPage, currentPage ] );

  useEffect(() => {
    const auxPageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalRows / notificacionesPerPage); i++) {
      auxPageNumbers.push(i);
    }
    console.log("auxPageNumberFormulario: ", auxPageNumbers)
    setPageNumbers(auxPageNumbers);
  }, [totalRows]);

  const leerNotificacion = async (idNotificacion) => {
    axiosConfig({
      method: 'PUT',
      url: `dashboard/notifications/`+idNotificacion,
      data: {}
    }).then( async () => {
      getNotificaciones();
  } )
  };

  useEffect(() => {
    getNotificaciones();
    // eslint-disable-next-line
  }, [getNotificaciones]);
  
  return (
    <div className='w-full'  >
      <div className='pl-6 pt-12 ' >
        <div className='Login w-10/12 bg-white shadow rounded' style={ {minHeight: '540px'} }>
          <div className='rojodiv w-full h-6 rounded-t'></div>
          <div className=' titulo pl-6 pt-5 pb-3 text-xl flex flex-row' >
            <div className='pt-1 pr-2'>
              <FaBell />
            </div>
            Notificaciones
          </div>
              {notificaciones && notificaciones.map((notificacion) => {
                return (
                  <div className='p-2 pb-1 pt-1'>
                    <div className='p-2 bg-gray-200	rounded-t '>
                      {notificacion.content}
                      <button className='float-right pt-1 pr-1' onClick={() => leerNotificacion(notificacion.id_notification)}><FaCheckDouble/></button>
                    </div>
                  </div>
                );
              })}
              {notificaciones.length === 0 && 
                  <div className='p-2 pb-1 pt-1'>
                    <div className='p-2 bg-gray-200	rounded-t '>
                      NO HAY NOTIFICACIONES
                    </div>
                  </div>
                }
          {notificaciones.length > 0 && <div className='flex flex-row-reverse mt-3 pb-5 pr-5'>
              {/* Rows per page select */}

              {/* Page navigator */}
              <div className='inline-flex'>
                <button
                  onClick={() => {
                    setCurrentPage((prev) => {
                      return prev - 1;
                    });
                  }}
                  disabled={currentPage === 1 ? 'disabled' : ''}
                  className={`btn-primary bg-blue-500 text-white py-1 px-2
								rounded-l focus:outline-none text-xl ${
                  currentPage === 1
                    ? 'opacity-50 cursor-not-allowed'
                    : 'opacity-75 hover:opacity-100'
                }`}
                >
                  <BsArrowLeftShort />
                </button>
                <select
                  value={currentPage}
                  onChange={(event) =>
                    setCurrentPage(parseInt(event.target.value))
                  }
                  className='btn-primary bg-blue-500 text-white py-1 px-2 focus:outline-none'
                >
                  {pageNumbers.map((p) => {
                    return (
                      <option key={p} value={p}>
                        {p}
                      </option>
                    );
                  })}
                </select>
                <button
                  onClick={() => {
                    setCurrentPage((prev) => {
                      return prev + 1;
                    });
                  }}
                  disabled={
                    currentPage === pageNumbers.length ? 'disabled' : ''
                  }
                  className={`btn-primary bg-blue-500 text-white py-1 px-2 rounded-r focus:outline-none text-xl ${
                    currentPage === pageNumbers.length
                      ? 'opacity-50 cursor-not-allowed'
                      : 'opacity-75 hover:opacity-100'
                  }`}
                >
                  <BsArrowRightShort />
                </button>
              </div>
            </div>}
        </div>
        
      </div>
    </div>
  );
};

export default Notificaciones;
