import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { BiRefresh } from 'react-icons/bi';

import { FaSearch, FaClipboardCheck } from 'react-icons/fa';
import { BsCardList, BsArrowLeftShort, BsArrowRightShort, } from 'react-icons/bs';
import axiosConfig from '../../utils/axiosConfig';
// import { Pagination } from '../Pagination';

const Visitadashboard = () => {
  
   const tableNames = ['Drive', 'Plaza', 'Opciones'];
   const [drivePerPage, setDrivePerPage] = useState(10);
   const [currentPage, setCurrentPage ] = useState(1);
   const [pageNumbers, setPageNumbers] = useState([]);
   const [Drives, setDrives] = useState([]);
   const [search, setSearch] = useState('');
   const [totalRows, setTotalRows] = useState();

  useEffect(() => {
    setCurrentPage(1);
  }, [drivePerPage, search]);

   const getDrives = useCallback(async () => {
      const { count, rows } = await axiosConfig({
         method: 'get',
         url: `/drives`,
         params: {
            limit: drivePerPage,
            page: currentPage,
            // search: search,
         },
      } );

      if( rows && rows.length > 0 ){
        if (count > 0) {
          setTotalRows(count);
        }
         setDrives( rows );
      }
  }, [ drivePerPage, currentPage ] )

  useEffect(() => {
    getDrives();
  }, [getDrives, search, drivePerPage, currentPage]);

  useEffect(() => {
    const auxPageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalRows / drivePerPage); i++) {
      auxPageNumbers.push(i);
    }
    setPageNumbers(auxPageNumbers);
  }, [totalRows]);


  return (
    <div className='pl-6 pb-6 pr-6'>
      <div className='flex flex-row justify-between w-full py-6'>
        <div className='titulo  text-3xl'>Visita a Drive</div>
      </div>

      <div className='Login w-full bg-white shadow rounded'>
        <div className='rojodiv w-full h-6 rounded-t'></div>
        <div className='border-b-2 titulo pl-6 pt-5 pb-3 text-xl'>
          Lista de Drives asignados
        </div>

        <div className='p-8'>
          <div className='flex flex-row justify-between'>
            <div className='flex flex-row '>
              <div className='tablamenu pt-1 pr-2'>Numero de registros</div>

              <div className='flex flex-row'>
                <select
                  value={drivePerPage}
                  onChange={(event) =>
                    setDrivePerPage(parseInt(event.target.value))
                  }
                  className='   border-gray-400 border rounded '
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                </select>
              </div>
            </div>

            <div className='flex flex-row '>
              <div className='w-2'></div>
              <button
                className=' px-1 bg-gray-100 border-gray-400 border rounded'
                onClick={(event) => {
                  event.preventDefault();
                  getDrives();
                }}
              >
                <BiRefresh size={24} />
              </button>
              <div className='w-2'></div>
              <button
                className='  px-2 bg-gray-300 border-gray-300 border-b-2 border-l-2 border-t-2 rounded-l'
                onClick={(event) => {
                  event.preventDefault();
                  getDrives();
                }}
              >
                <FaSearch />
              </button>

              <input
                className='w-60  border-2 border-gray-300 rounded-r	 pt-1 '
                placeholder='Buscar'
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </div>
          </div>
          {Drives.length === 0 && <div className='w-full grid justify-items-center text-xl pt-10'> No se encontraron drives por el momento. </div>}
          {Drives.length > 0 && <table className='content-table w-full'>
            <thead>
              <tr>
                {tableNames.map((name, index ) => {
                  return <th key={index}>{name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {Drives.map((Drives, index ) => {
                return (
                  <tr key={ index } >
                    <td>{Drives.nombre}</td>
                    <td>{Drives.direccion}</td>
                    <td>
                      <Link
                        to={`/inicio/visitas/listaformularios/${Drives.drive_id}`}
                      >
                        <button className=' botonazuldetalles  '>
                          <div className='flex flex-row justify-items-center'>
                            <div className='pt-1 px-1'>
                              <FaClipboardCheck />
                            </div>
                            <div>Comenzar visita</div>
                          </div>
                        </button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>}
          {/* <div>
            Mostrando registros del 1 al 10 de un total de 0000 registros
          </div> */}
          <div className='flex   mt-3 flex-row-reverse'>
            {/* Rows per page select */}

            {/* Page navigator */}
            {/* <Pagination {...{prevPage, currentPage, nextPage, change, setCurrentPage }} /> */}
            { <div className='inline-flex	'>
              <button
                onClick={() => {
                  setCurrentPage((prev) => {
                    return prev - 1;
                  });
                }}
                disabled={currentPage === 1 ? 'disabled' : ''}
                className={`btn-primary bg-blue-500 text-white py-1 px-2 
								rounded-l focus:outline-none text-xl ${
                  currentPage === 1
                    ? 'opacity-50 cursor-not-allowed'
                    : 'opacity-75 hover:opacity-100'
                }`}
              >
                <BsArrowLeftShort />
              </button>
              <select
                value={currentPage}
                onChange={(event) =>
                  setCurrentPage(parseInt(event.target.value))
                }
                className='btn-primary bg-blue-500 text-white py-1 px-2 focus:outline-none'
              >
                {pageNumbers.map((p, index) => {
                  return (
                    <option key={index} value={p}>
                      {p}
                    </option>
                  );
                })}
              </select>
              <button
                onClick={() => {
                  setCurrentPage((prev) => {
                    return prev + 1;
                  });
                }}
                disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
                className={`btn-primary bg-blue-500 text-white py-1 px-2 rounded-r focus:outline-none text-xl ${
                  currentPage === pageNumbers.length
                    ? 'opacity-50 cursor-not-allowed'
                    : 'opacity-75 hover:opacity-100'
                }`}
              >
                <BsArrowRightShort />
              </button>
            </div> }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Visitadashboard;
