import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FaPlus, FaEdit, FaEraser, FaToggleOff, FaToggleOn, FaSearch } from 'react-icons/fa';
import { BsArrowLeftShort, BsArrowRightShort, BsCardList } from 'react-icons/bs';
import { BiRefresh } from 'react-icons/bi';
import axiosConfig from '../../utils/axiosConfig';

const Metadashboard = () => {

  const tableNames = ['Nombre', 'Categoría', 'Negocio', 'Objetivo mensual', 'Opciones'];
  const [metasPerPage, setMetasPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [totalRows, setTotalRows] = useState();
  const [search, setSearch] = useState('');
  const [metas, setMetas] = useState([]);

  useEffect(() => {
    getGoalsConfiguration();
    setCurrentPage(1);
  }, [metasPerPage, search]);

  const getGoalsConfiguration = async () => {
    const { count, rows } = await axiosConfig({
       method: 'get',
       url: `/goalsConfiguration`,
    });

    if( rows && rows.length > 0 ) {
      if (count > 0) {
        setTotalRows(count);
      }
       setMetas(rows)
    } 
  };

 const toggleActive = useCallback( async ( idBatch ) => {
    
    const data = await axiosConfig({
       method: 'PUT',
       url: `/goalsConfiguration/active/${ idBatch }`
    } );

    if( data ){
       getGoalsConfiguration();
    }
 }, [ ] );

  useEffect(() => {
    const auxPageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalRows / metasPerPage); i++) {
      auxPageNumbers.push(i);
    }
    setPageNumbers(auxPageNumbers);
  }, [totalRows]);



  return (
    <div className='pl-6 pb-8 pr-6'>
      <div className='flex flex-row justify-between w-full pt-6'>
        <div className='titulo pb-6 text-3xl'>Configuración de metas</div>
        <Link to={`/inicio/configMetas/createMeta`}>
          <button className='botonverde py-1 flex flex-row w-60 h-10 justify-items-center rounded-sm shadow'>
            <div className='pt-1 px-2'>
              <FaPlus />
            </div>
            <div>Crear nueva Configuración</div>
          </button>
        </Link>
      </div>

      <div className='Login w-full bg-white shadow rounded'>
        <div className='rojodiv w-full h-6 rounded-t'></div>
        
        <div className='p-8'>
          <div className='flex flex-row justify-between'>
            <div className='flex flex-row '>
              <div className='tablamenu pt-1 pr-2'>Numero de registros</div>

              <div className='flex flex-row'>
                <select
                  value={metasPerPage}
                  onChange={(event) =>
                    setMetasPerPage(parseInt(event.target.value))
                  }
                  className='   border-gray-400 border rounded '
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                </select>
              </div>
            </div>

            <div className='flex flex-row '>
              <button className=' px-1.5 bg-gray-100 border-gray-400 border rounded'>
                <FaEraser size={16} />
              </button>
              <div className='w-2'></div>
              <button
                className=' px-1 bg-gray-100 border-gray-400 border rounded'
                // onClick={(event) => {
                //   event.preventDefault();
                //   getDrives();
                // }}
              >
                <BiRefresh size={24} />
              </button>
              <div className='w-2'></div>
              <button
                className='  px-2 bg-gray-300 border-gray-300 border-b-2 border-l-2 border-t-2 rounded-l'
                // onClick={(event) => {
                //   event.preventDefault();
                //   getDrives();
                // }}
              >
                <FaSearch />
              </button>

              <input
                className='w-60  border-2 border-gray-300 rounded-r	 pt-1 '
                placeholder='Buscar'
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </div>
          </div>

          <div className='p-4'>
          <div style={{ overflow: "auto" }}>
            <table className='content-table w-full'>
              <thead>
                <tr>
                  {tableNames.map((name) => {
                    return <th key={name}>{name}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
               { metas.map( ( meta, index ) => {
                  return <tr key={index}>
                      <td> {meta.name} </td>
                      <td> {meta.Category.name} </td>
                      <td> {meta.Business.name} </td>
                      <td> {meta.goal} </td>
                      <td>
                      <div className="flex flex-row justify-center">
                        <Link to={`/inicio/configMetas/metasdetalles/${ meta.id_goal_config }`}>
                          <button className='flex flex-row mx-3 botonazuldetalles'>
                            <div className='pt-1 px-1'>
                                <BsCardList />
                            </div>
                            <div>Detalles</div>
                          </button>
                        </Link>
                        <Link to={`/inicio/configMetas/update/${ meta.id_goal_config }`} >
                          <button className='flex flex-row mx-3 botonverdeeditar'>
                              <div className='pt-1 px-1'>
                              <FaEdit />
                              </div>
                              <div>Editar</div>
                          </button>
                        </Link>
                        { 
                          <button
                              type="button"
                              className={`flex flex-row mx-3 text-4xl ${ meta.active ? "text-green-500" : "text-gray-700" } `}
                              title="Habilitar / Inhabilitar"
                              onClick={ () => toggleActive( meta.id_goal_config ) }
                          >
                          <div className='pt-1 px-1 	'>
                              { meta.active ?  <FaToggleOn /> : <FaToggleOff /> }
                          </div>
                        </button>
                        }
                        </div>
                      </td>
                    </tr>
                })}
                
              </tbody>
            </table>
            
          </div>
        </div>
          <div className='flex flex-row-reverse mt-3'>
            {/* Rows per page select */}

            {/* Page navigator */}
            <div className='inline-flex'>
              <button
                onClick={() => {
                  setCurrentPage((prev) => {
                    return prev - 1;
                  });
                }}
                disabled={currentPage === 1 ? 'disabled' : ''}
                className={`btn-primary bg-blue-500 text-white py-1 px-2 
								rounded-l focus:outline-none text-xl ${
                  currentPage === 1
                    ? 'opacity-50 cursor-not-allowed'
                    : 'opacity-75 hover:opacity-100'
                }`}
              >
                <BsArrowLeftShort />
              </button>
              <select
                value={currentPage}
                onChange={(event) =>
                  setCurrentPage(parseInt(event.target.value))
                }
                className='btn-primary bg-blue-500 text-white py-1 px-2 focus:outline-none'
              >
                {pageNumbers.map((p) => {
                  return (
                    <option key={p} value={p}>
                      {p}
                    </option>
                  );
                })}
              </select>
              <button
                onClick={() => {
                  setCurrentPage((prev) => {
                    return prev + 1;
                  });
                }}
                disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
                className={`btn-primary bg-blue-500 text-white py-1 px-2 rounded-r focus:outline-none text-xl ${
                  currentPage === pageNumbers.length
                    ? 'opacity-50 cursor-not-allowed'
                    : 'opacity-75 hover:opacity-100'
                }`}
              >
                <BsArrowRightShort />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Metadashboard;
