import React, { useEffect, useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaPlus, FaEraser, FaEdit } from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import Modal from 'react-modal';
import { AiOutlineCloseSquare } from 'react-icons/ai';
import Select from 'react-select';
import { RiSave3Fill } from 'react-icons/ri';
import axiosConfig from '../../utils/axiosConfig';
import Swal from 'sweetalert2';
import { NotificationManager } from 'react-notifications';

Modal.setAppElement('#root');

const estiloModal = {
   content: {
      top:'50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: '30%',
      height: '65%',
      transform: 'translate(-50%, -50%)',
   },
}

const DetalleRegion = () => {
  const [openTab, setOpenTab] = React.useState(1);
  const { id: idFdetalle } = useParams();
  const [ openModal, setOpenModal ] = useState( false );
  const [ editarMode, setEditarMode ] = useState( false );
  const [fdetalle, setFdetalle] = useState({});
  const [lstCiudades, setLstCiudades] = useState([]);
  const [lstCiudadesSelect, setLstCiudadesSelect] = useState([]);
  const [ idCiudad, setIdCiudad] = useState('');


  const [nombreRegion, setNombreRegion] = useState('');
  const [nomenclatura, setNomenclatura] = useState('');
  const [idCoordinador, setIdCoordinador] = useState('');
  const [lstCoordinadores, setLstCoordinadores] = useState([]);

  const getFdetalles = async () => {
    const data = await axiosConfig({
      method: 'get',
      url: `regions/${idFdetalle}`,
    });

    if (data) {
      setFdetalle(data);
      setLstCiudades(data.Cities)
    }
  };
  useEffect(() => {
    getFdetalles();
   
    // eslint-disable-next-line
  }, [idFdetalle]);

  useEffect( () => {
  }, [idFdetalle]);

  const handlenModal = () => {
    setOpenModal( s => !s );
    setIdCiudad('');
    getCiudades();
    getFdetalles();
 }

 const handleEdit = () => {
   setEditarMode(s => !s);
   getCoordinadores();
   setNombreRegion(fdetalle.name);
   setNomenclatura(fdetalle.nomenclature);
   setIdCoordinador({label: fdetalle.Coordinator.name, value: fdetalle.Coordinator.user_id});
 }

 const getCiudades = async () => {
  const data = await axiosConfig({
     method: 'get',
     url: `/regions/city`,
  } );
  
  if (data) {
     let option = [];
     for (let i = 0; i < data.rows.length; i++) {
        let objAux = {};
        objAux = {
           value: data.rows[i].city_id,
           label: data.rows[i].name+" - "+data.rows[i].State.name
        }
        option.push(objAux);
     }
     setLstCiudadesSelect(option);
  }
};

const getCoordinadores = async () => {
  const data = await axiosConfig({
     method: 'get',
     url: `/usuarios/usuariosByRole/Coordinador`,
  } );
  
  if (data) {
     let option = [];
     for (let i = 0; i < data.rows.length; i++) {
        let objAux = {};
        objAux = {
           value: data.rows[i].user_id,
           label: data.rows[i].name
        }
        option.push(objAux);
     }
     setLstCoordinadores(option);
  }
};

const handleChangeIdCiudad = (target) => {
  console.log("target", target)
  const idCiudad = target.value;
  console.log("idCiudad: ", idCiudad)
  setIdCiudad({ label: target.label, value: target.value });
}

const guardarCiudad = async () => {
  if(idCiudad === ''){
      Swal.fire('No puede dejar vacío el campo de nombre ciudad, favor de ingresar un nombre válido', '', 'error');
  }else{
      Swal.fire({
          title: '¿Estás seguro que quieres guardar?',
          showDenyButton: true,
          confirmButtonText: 'Guardar',
          denyButtonText: `Cancelar`,
          } ).then( ( result ) => {
          if( result.isConfirmed ){
              axiosConfig({
                  method: 'POST',
                  url: `regions/city`,
                  data: {
                      city_id: idCiudad.value,
                      region_id: idFdetalle
                  }
              }).then( async () => {
                  NotificationManager.success( "", "Guardado con éxito.", 2500 )   
                  await getFdetalles();
                  setOpenModal( s => !s );
              } ).catch( () => NotificationManager.success( "Algo salió mal, contacte a un administrador.", "Oops", 2500 ) )
          } else {
              if( result.isDenied ) {
              
              }
          }
          } )
  }
}

function eliminarCiudad(idCiudad) {
  Swal.fire({
    icon: 'warning',
    title: 'Aviso',
    text: 'Está a punto de eliminar esta ciudad. ¿Eliminar ciudad? ',
    showDenyButton: true,

    denyButtonText: 'Cancelar',
    confirmButtonText: 'Aceptar',

    confirmButtonColor: '#2F80ED',
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      axiosConfig({
        method: 'DELETE',
        url: `regions/city`,
        data: {
          city_id: idCiudad,
          region_id: idFdetalle
        }
      }).then( async () => {
          getFdetalles();
          Swal.fire('Eliminado', '', 'error');
    } )
      
    } else if (result.isDenied) {

    }
  });
}

const handleChangeNombre = (evt) => {
  const name = evt.target.value;
  setNombreRegion(name);
};

const handleChangeNomenclatura = (evt) => {
  const name = evt.target.value;
  setNomenclatura(name);
};

const handleChangeIdCoordinador = (target) => {
  const name = target;
  setIdCoordinador({label: target.label, value: target.value});
};

const guardarEdicionRegion = async () => {
  if(nombreRegion === '' && nomenclatura === '' && idCoordinador === ''){
      NotificationManager.warning( "No puede dejar los campos vacíos.", "Validation.", 2500 )
  }else{
    Swal.fire({
      title: '¿Estás seguro que quieres guardar los datos de la región?',
      showDenyButton: true,
      confirmButtonText: 'Guardar',
      denyButtonText: `Cancelar`,
    } ).then( ( result ) => {
      if( result.isConfirmed ){
         axiosConfig({
            method: 'PUT',
            url: `/regions/`+idFdetalle,
            data: {
              name: nombreRegion,
              nomenclature: nomenclatura,
              id_coordinator: idCoordinador.value
            }
          }).then( async () => {
            NotificationManager.success( "", "Guardado con éxito.", 2500 )
            handleEdit();
            await getFdetalles();
        } ).catch( () => NotificationManager.success( "Algo salió mal, contacte a un administrador.", "Oops", 2500 ) )
      } else {
        if( result.isDenied ) {
          
        }
      }
    } )
  }
  
}

  return (
    <div className='pl-6 '>
      
      <div className='flex flex-row justify-between w-full pb-3'>
        <div className='titulo  text-3xl'>Detalle región</div>
      </div>
      <div className=' '>
        <div className='Login w-full bg-white shadow rounded'>
          <div className='rojodiv w-full h-6 rounded-t'></div>
          <div className='w-full'>
            <ul className='flex  flex-wrap pb-4 flex-row' role='tablist'>
              <li>
                <Link to='/inicio/regiones' >
                    <div className='text-lg px-4 border-b-2 pt-3 pb-4 block leading-normal'>
                        <MdArrowBack size={24} />
                    </div>
                </Link>  
              </li>
              <li>
                <a
                  className={
                    'text-xs px-5 py-3 border-b-2  block leading-normal  ' +
                    (openTab === 1
                      ? ' text-lg	 border-b-4 bg-white TabsActive'
                      : 'rojoletra text-lg	 bg-white Tabs 	')
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle='tab'
                  href='#link1'
                  role='tablist'
                >
                  
                  Información
                </a>
              </li>

              <li className='-mb-px mr-2 last:mr-0 flex-auto text-center'>
                <span
                  className={
                    'text-xs px-5 py-3 border-b-2  block leading-normal ' +
                    (openTab === 99
                      ? ' text-lg	 border-b-4 bg-white TabsActive'
                      : 'text-white  text-lg	 bg-white Tabs 	')
                  }
                >
                  <div>.</div>
                </span>
              </li>
            </ul>

            <div className={openTab === 1 ? 'block' : 'hidden'} id='link1'>
              {!editarMode && <div className=' flex flex-row p-8'>
                <div className='w-1/5'>
                  <div className='font-black'>Nombre</div>
                  {fdetalle.name}
                </div>
                <div className='w-1/5'>
                  <div className='font-black'>Nomenclatura</div>
                  {fdetalle.nomenclature}
                </div>
                <div className='w-1/4'>
                  <div className='font-black'>Coordinador</div>
                  {fdetalle.Coordinator && fdetalle.Coordinator.name}
                </div>
                <div className='flex flex-row justify-end w-1/6 pr-6'>
                    <button className='flex flex-row mx-3 botonverdeeditar' onClick={handleEdit}>
                      <div className='pt-1 px-1'>
                      <FaEdit />
                      </div>
                      <div>Editar</div>
                    </button>
                </div>
              </div>}
              {editarMode && <div className=' flex flex-row p-8'>
                <div className='w-1/5 mr-5'>
                  <div className='font-black'>Nombre</div>
                  <input 
                        type="text"
                        value={nombreRegion}
                        onChange={evt => handleChangeNombre(evt)}
                        className='w-full border border-gray-300 rounded p-1'
                        placeholder='Ingrese el nombre de la ciudad'
                    />
                </div>
                <div className='w-1/5 mr-5'>
                  <div className='font-black'>Nomenclatura</div>
                  <input 
                        type="text"
                        value={nomenclatura}
                        onChange={evt => handleChangeNomenclatura(evt)}
                        className='w-full border border-gray-300 rounded p-1'
                        placeholder='Ingrese la nomenclatura'
                    />
                </div>
                <div className='w-1/4 mr-5'>
                  <div className='font-black'>Coordinador</div>
                  <Select 
                     className='rounded w-full'
                     noOptionsMessage={() => 'No hay ciudades.'}
                     value={idCoordinador}
                     onChange={(value) => handleChangeIdCoordinador(value)} 
                     options={lstCoordinadores}
                  />
                </div>
                <div className='flex flex-row justify-end w-1/6 pr-6 pb-5'>
                    <button className='flex flex-row mx-3 mt-5 botonverdeeditar' onClick={guardarEdicionRegion}>
                      <div className='pt-1 px-1'>
                        <RiSave3Fill className=' mx-auto text-2xl'/>
                      </div>
                      <div>Guardar</div>
                    </button>
                </div>
              </div>}
              
            </div>
          </div>
        </div>
      </div>


      <div className='flex flex-row justify-between w-full pt-6'>
          <div className='titulo pb-6 text-3xl'></div>
          <button className='botonverde py-4 flex flex-row w-50 h-10 justify-items-center rounded-sm shadow'  onClick={ handlenModal }>
            <div className='pt-1 px-2'>
            <FaPlus />
            </div>
            <div>Asignar ciudad</div>
          </button>
      </div>
      <div className='flex flex-row'>
          <div className='Login w-full bg-white shadow rounded mt-5'>
            <div className='rojodiv w-full h-4 rounded-t'></div>
            <div className='flex flex-row border-b-2 titulo pl-6 pt-3 pb-3 text-xl'>
                Ciudades asignadas a la región
            </div>
            
            <div className='p-4'>
                <div style={{ overflow: "auto" }}>
                  {lstCiudades.length > 0 && <table className='content-table w-full'>
                      <thead>
                          <tr>
                            <th>Ciudad</th>
                            <th>Estado</th>
                            <th>Opciones</th>
                          </tr>
                      </thead>
                      <tbody>
                            {lstCiudades.map((city) => {
                            return <tr>
                                      <td>{city.name}</td>
                                      <td>{city.State.name}</td>
                                      <td>
                                      <div className="flex flex-row justify-center">
                                          <button className='flex flex-row mx-3 botonrojodetalles' onClick={() => eliminarCiudad(city.city_id)}>
                                          <div className='pt-1 px-1'>
                                            <FaEraser />
                                          </div>
                                          <div>Eliminar</div>
                                          </button>                     
                                      </div>
                                      </td>
                                    </tr>
                            })}
                      </tbody>
                  </table>}
                  {lstCiudades.length === 0 && <div className='w-full grid justify-items-center text-xl'> NO HAY CIUDADES ASIGNADAS A LA REGIÓN. </div>}
                </div>
            </div>  
          </div>
      </div>

      <Modal  isOpen={ openModal } contentdiv='Usuario Modal' style={estiloModal} onRequestClose={ () => handlenModal() } >
                     
               <div className='flex flex-row justify-between border-b-2 pb-2'>
                  <div className='titulo text-xl'>
                     Asignación de ciudad
                  </div>
      
                  <button className='rojoletra' onClick={ () => setOpenModal( false ) } >
                  <AiOutlineCloseSquare size={28} />
                  </button>
               </div>
      
               <div className='w-1/12'  >
                  <p className='my-2' >
                     Ciudad
                  </p>
               </div>
                  
               <div className='w-11/12 flex flex-row'>
               <Select 
                     className='rounded w-full'
                     noOptionsMessage={() => 'No hay ciudades.'}
                     value={idCiudad}
                     onChange={(value) => handleChangeIdCiudad(value)} 
                     options={lstCiudadesSelect.filter( ( item ) => {
                        const flat = lstCiudades.find( ( elem ) => elem.city_id === item.value )
                        return !flat
                     } )}
                  />
               </div>
      
               <hr  className='mt-5'/>
      
               <div className='flex flex-row mt-5'>
                  <button type='button' className='botonverde m-auto px-7 rounded shadow' onClick={guardarCiudad}>
                  <div className='pt-1 px-2 flex flex-row '>
                     <span className=' px-1'>
                        <RiSave3Fill className=' mx-auto text-2xl	' />
                     </span>
                     Guardar
                  </div>
                  </button>
               </div>
      
               </Modal>
    </div>

    
    
  );
};

export default DetalleRegion;
