import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { BsCardList, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { BiRefresh } from 'react-icons/bi';
import { FaSearch } from 'react-icons/fa';

import axiosConfig from '../../utils/axiosConfig';

const moment = require('moment');

const VacacionesDash = () => {

  const tableNames = ['Usuario', 'Fecha inicio', 'Fecha fin', 'Opciones'];
  const [formularioPerPage, setformularioPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [totalRows, setTotalRows] = useState();
  const [vacations, setVacations] = useState([]);
  const [search, setSearch] = useState('');


  useEffect(() => {
    setCurrentPage(1);
  }, [formularioPerPage, search]);

  const getVacations = useCallback( async () => {
    const { count, rows } = await axiosConfig({
      method: 'get',
      url: `/vacations`,
      params: {
        limit: formularioPerPage,
        page: currentPage,
        // search: search,
        id_status: 1
      },
    });

    if (rows) {
      if (count > 0) {
        setTotalRows(count);
      }
      setVacations(rows);
    }
  }, [ formularioPerPage, currentPage ] )

  useEffect(() => {
    getVacations();
  }, [getVacations,  search, formularioPerPage, currentPage]);

  useEffect(() => {
    const auxPageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalRows / 3); i++) {
      auxPageNumbers.push(i);
    }
    setPageNumbers(auxPageNumbers);
  }, [totalRows]);

  return (
    <div className='pl-6'>
      <div className='flex flex-row justify-between w-11/12 p-3'>
        <div className='titulo  text-3xl'>Vacaciones</div>
      </div>

      <div className='Login w-11/12 bg-white shadow rounded'>
        <div className='rojodiv w-full h-6 rounded-t'></div>
        <div className='border-b-2 titulo pl-6 pt-5 pb-3 text-xl'>
          Listado de vacaciones
        </div>
        <div className='p-8'>
          <div className='flex flex-row justify-between'>
            <div className='flex flex-row '>
              <div className='tablamenu pt-1 pr-2'>Numero de registros</div>

              <div className='flex flex-row'>
                <select
                  value={formularioPerPage}
                  onChange={(event) =>
                    setformularioPerPage(parseInt(event.target.value))
                  }
                  className='   border-gray-400 border rounded '
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                </select>
              </div>
            </div>

            <div className='flex flex-row '>
              <div className='w-2'></div>
              <button
                className=' px-1 bg-gray-100 border-gray-400 border rounded'
                // onClick={(event) => {
                //   event.preventDefault();
                //   getDrives();
                // }}
              >
                <BiRefresh size={24} />
              </button>
              <div className='w-2'></div>
              <button
                className='  px-2 bg-gray-300 border-gray-300 border-b-2 border-l-2 border-t-2 rounded-l'
                // onClick={(event) => {
                //   event.preventDefault();
                //   getDrives();
                // }}
              >
                <FaSearch />
              </button>

              <input
                className='w-60  border-2 border-gray-300 rounded-r	 pt-1 '
                placeholder='Buscar'
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </div>
          </div>
          {vacations.length > 0 && <table className='content-table w-full'>
            <thead>
              <tr>
                {tableNames.map((name) => {
                  return <th key={name}>{name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {vacations.map((vacation) => {
                return (
                  <tr key={vacation.id_vacation}>
                    <td>{vacation.user.name}</td>
                    <td>{moment(vacation.start_date).utc().format('YYYY/MM/DD')}</td>
                    <td>{moment(vacation.final_date).utc().format('YYYY/MM/DD')}</td>

                    <td>
                      <button className=' botonazuldetalles   '>
                        <Link
                          to={`/inicio/vacaciones/detallesvacaciones/${vacation.id_vacation}`}
                          className='w-16'
                        >
                          <div className='flex flex-row'>
                            <div className='pt-1 px-1'>
                              <BsCardList />
                            </div>
                            <div>Detalles</div>
                          </div>
                        </Link>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>}
          {vacations.length === 0 && <div className='w-full grid justify-items-center text-xl pt-10'> No se encontraron vacaciones por el momento. </div>}
          <div className='flex flex-row-reverse mt-3'>
            {/* Rows per page select */}

            {/* Page navigator */}
            <div className='inline-flex'>
              <button
                onClick={() => {
                  setCurrentPage((prev) => {
                    return prev - 1;
                  });
                }}
                disabled={currentPage === 1 ? 'disabled' : ''}
                className={`btn-primary bg-blue-500 text-white py-1 px-2 
								rounded-l focus:outline-none text-xl ${
                  currentPage === 1
                    ? 'opacity-50 cursor-not-allowed'
                    : 'opacity-75 hover:opacity-100'
                }`}
              >
                <BsArrowLeftShort />
              </button>
              <select
                value={currentPage}
                onChange={(event) =>
                  setCurrentPage(parseInt(event.target.value))
                }
                className='btn-primary bg-blue-500 text-white py-1 px-2 focus:outline-none'
              >
                {pageNumbers.map((p) => {
                  return (
                    <option key={p} value={p}>
                      {p}
                    </option>
                  );
                })}
              </select>
              <button
                onClick={() => {
                  setCurrentPage((prev) => {
                    return prev + 1;
                  });
                }}
                disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
                className={`btn-primary bg-blue-500 text-white py-1 px-2 rounded-r focus:outline-none text-xl ${
                  currentPage === pageNumbers.length
                    ? 'opacity-50 cursor-not-allowed'
                    : 'opacity-75 hover:opacity-100'
                }`}
              >
                <BsArrowRightShort />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VacacionesDash;
