import React, { useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import Swal from 'sweetalert2';
import axiosConfig from '../../utils/axiosConfig';
import { FaPlus } from 'react-icons/fa';
import Select from 'react-select';
import { RiDeleteBin6Fill, RiSave3Fill } from 'react-icons/ri';

const CrearRegion = () => {
  const { id: idVdetalle } = useParams();
  const history = useHistory();
  const [vdetalle, setVdetalle] = useState({});
  const [idCoordinador, setIdCoordinador] = useState('');
  const [nombre, setNombre] = useState('');
  const [nomenclatura, setNomenclatura] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [cities, setCities] = useState([]);
  const [lstCoordinadores, setLstCoordinadores] = useState([]);
  const [ idCiudad, setIdCiudad] = useState('');
  const [lstCiudades, setLstCiudades] = useState([]);
  const [lstCiudadesSelect, setLstCiudadesSelect] = useState([]);

  const getCiudades = async () => {
    const data = await axiosConfig({
       method: 'get',
       url: `/regions/city`,
    } );
    
    if (data) {
       let option = [];
       for (let i = 0; i < data.rows.length; i++) {
          let objAux = {};
          objAux = {
             value: data.rows[i].city_id,
             label: data.rows[i].name+" - "+data.rows[i].State.name
          }
          option.push(objAux);
       }
       setLstCiudadesSelect(option);
    }
  };

  const remove = ( index ) => {
    setCities( s => s.filter( ( _, i ) => i !== index ) )
  }

  const push = () => {
    setCities(s => [ ...s, {
			nombre: idCiudad.label,
      city_id: idCiudad.value
		} ] );
    setIdCiudad('');
  }

  const handleChangeCiudad = (evt) => {
    const name = evt.target.value;
    setCiudad(name);
  };

  const handleChangeNombre = (evt) => {
    const name = evt.target.value;
    setNombre(name);
  };
  
  const handleChangeNomenclatura = (evt) => {
    const name = evt.target.value;
    setNomenclatura(name);
  };

  useEffect(() => {
    getCiudades();
    getCoordinadores();
    // eslint-disable-next-line
  }, [idVdetalle]);

  function crearRegion() {
    let objCiudades = [];
    cities.forEach(city => {
      objCiudades.push({city_id : city.city_id});
    });
    Swal.fire({
      icon: 'warning',
      title: 'Aviso',
      text: 'Está seguro que quiere crear el estado?',
      showDenyButton: true,

      denyButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',

      confirmButtonColor: '#2F80ED',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axiosConfig({
          method: 'POST',
          url: `/regions`,
          data: {
            name: nombre,
            nomenclature: nomenclatura,
            id_coordinator: idCoordinador.value,
            cities: objCiudades
          }
        }).then( async () => {
          Swal.fire('Región creado', '', 'success');
          setNombre('');
          setNomenclatura('');
          history.push( `/inicio/regiones` );
      } ).catch(() => {
        Swal.fire('Se detectó un error, contactar con el administrador.', '', 'error');
     });
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info');
      }
    });
  }

  const handleChangeIdCoordinador = (target) => {
    const name = target;
    setIdCoordinador({label: target.label, value: target.value});
  };

  const getCoordinadores = async () => {
    const data = await axiosConfig({
       method: 'get',
       url: `/usuarios/usuariosByRole/Coordinador`,
    } );
    
    if (data) {
       let option = [];
       for (let i = 0; i < data.rows.length; i++) {
          let objAux = {};
          objAux = {
             value: data.rows[i].user_id,
             label: data.rows[i].name
          }
          option.push(objAux);
       }
       setLstCoordinadores(option);
    }
  };

  const handleChangeIdCiudad = (target) => {
    console.log("target", target)
    const idCiudad = target.value;
    console.log("idCiudad: ", idCiudad)
    setIdCiudad({ label: target.label, value: target.value });
  }

  return (
    <div>
      <div className=' grid justify-items-center pt-10'>
        <div className='Login w-11/12 bg-white shadow rounded'>
        <div className='rojodiv w-full h-6 rounded-t'></div>
            <div className='w-full'>
              <ul className='flex  flex-wrap pb-4 flex-row' role='tablist'>
                <li>
                  <Link to='/inicio/regiones' >
                      <div className='text-lg px-4 border-b-2 pt-3 pb-4 block leading-normal'>
                          <MdArrowBack size={24} />
                      </div>
                  </Link>  
                </li>
                <li>
                  <a
                    className={
                      'text-xs px-5 py-3 border-b-2  block leading-normal  ' + ' text-lg	 border-b-4 bg-white TabsActive'
                    }
                    data-toggle='tab'
                    href='#link1'
                    role='tablist'
                  >
                    
                    Crear región
                  </a>
                </li>
                <li className='-mb-px mr-2 last:mr-0 flex-auto text-center'>
                  <span
                    className={
                      'text-xs px-5 py-3 border-b-2  block leading-normal ' +  'text-white  text-lg	 bg-white Tabs 	'
                    }
                  >
                    <div>.</div>
                  </span>
                </li>
              </ul>
            </div>
            

          <div className='tablamenu p-4'>
          <div className=' flex flex-row p-8 pl-5'>
                <div className='w-1/5 mr-5'>
                  <div className='font-black'>Nombre</div>
                  <input 
                        type="text"
                        value={nombre}
                        onChange={evt => handleChangeNombre(evt)}
                        className='w-full border border-gray-300 rounded p-1'
                        placeholder='Ingrese el nombre de la ciudad'
                    />
                </div>
                <div className='w-1/5 mr-5'>
                  <div className='font-black'>Nomenclatura</div>
                  <input 
                        type="text"
                        value={nomenclatura}
                        onChange={evt => handleChangeNomenclatura(evt)}
                        className='w-full border border-gray-300 rounded p-1'
                        placeholder='Ingrese la nomenclatura'
                    />
                </div>
                <div className='w-1/4 mr-5'>
                  <div className='font-black'>Coordinador</div>
                  <Select 
                     className='rounded w-full'
                     noOptionsMessage={() => 'No hay coordinadores.'}
                     value={idCoordinador}
                     onChange={(value) => handleChangeIdCoordinador(value)} 
                     options={lstCoordinadores}
                  />
                </div>
              </div>
            <div className='titulo border-b pt-4 pb-1 pl-5 mb-4'> Ciudades del estado </div>

                        <div className='my-6  flex flex-row pl-4'>
                            <div className='w-1/3 mr-5'>
                              <div className='w-1/12'  >
                                  <p className='my-2' >
                                    Ciudad
                                  </p>
                              </div>
                                  
                              <div className='w-11/12 flex flex-row'>
                                <Select 
                                      className='rounded w-full'
                                      noOptionsMessage={() => 'No hay ciudades.'}
                                      value={idCiudad}
                                      onChange={(value) => handleChangeIdCiudad(value)} 
                                      options={lstCiudadesSelect.filter( ( item ) => {
                                          const flat = lstCiudades.find( ( elem ) => elem.city_id === item.value )
                                          return !flat
                                      } )}
                                    />
                              </div>
                            </div>
                            <button type='button' className='botonverde flex flex-row py-2 pl-4 mb-4 mt-9' onClick={ push } >
                              <div className='pt-1 pr-1'>
                                  <FaPlus />
                              </div>
                              Agregar ciudad
                            </button>
                        </div>
                     {cities.length > 0 && <table className='content-table w-full'>
                        <thead>
                            <tr>
                              <th>Nombre</th>
                            <th>
                              Opciones
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            {cities.map( ( city, index ) => (
                              <tr key={index}>
                                    <td>{city.nombre}</td>
                                    <td>
                                      <div className='col mx-5'>
                                          <button type='button'  className='secondary' onClick={() => remove( index ) } >
                                          <div className='text-red-700 py-5'>
                                            <RiDeleteBin6Fill size={25} />
                                          </div>
                                          </button>
                                      </div>
                                    </td>
                              </tr>
                                ) )
                            }
                        </tbody>
                     </table>}
                     {cities.length === 0 && <div className='w-full grid justify-items-center text-xl'> NO HAY CIUDADES SELECCIONADAS. </div>}
                      <div className='flex flex-row justify-center mt-5'>
                        <button type='button' className='botonverde flex flex-row py-2 pl-4 mb-4 mt-4 content-center' onClick={ crearRegion } >
                          <div className='pt-1 pr-1'>
                            <RiSave3Fill className=' mx-auto text-2xl'/>
                          </div>
                          Guardar
                        </button>
                      </div>
                      
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrearRegion;
