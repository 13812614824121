import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import axiosConfig from '../../utils/axiosConfig';
import Select from 'react-select'

const Reportes = () => {
  const [idReporte, setIdReporte] = useState('');
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');
  const [usuarios, setUsuarios] = useState([]);
  const [idCoordinador, setIdCoordinador] = useState('');
  const [usuariosRoles, setUsuariosRoles] = useState([]);
  const [idUsuario, setIdUsuario] = useState('');

  const [roles, setRoles] = useState([]);
  const [nombreRol, setNombreRol] = useState('');

  const [modulos, setModulos] = useState([]);
  const [lstModulo, setLstModulo] = useState([]);

  const [regiones, setRegiones] = useState([]);
  const [region, setRegion] = useState([]);

  const [categorias, setCategorias] = useState([]);
  const [lstCategorias, setLstCategorias] = useState([]);

  const downloadReport = async () => {
    try {
      let extension = 'xlsx';

      const response = await axiosConfig({
        method: 'get',
        url: idReporte.value === 1 || idReporte.value === 3 ? `/reportes/getReporteModulo` : idReporte.value === 2 ? `/reportes/getReporteRol` : idReporte.value === 4 ? `/reportes/getReporteVisita` : ``,
        params: {
          fechaInicio: fechaInicio,
          fechaFinal: fechaFin,
          ...(nombreRol ? { nombreRol: nombreRol.value } : {}),
          ...(region.length > 0 ? { idRegion: region.join(',') } : {}),
          ...(idUsuario ? { user_id: idUsuario.value } : {}),
          ...(lstCategorias.length !== 0 ? { strCategories: lstCategorias.join(',') } : {}),
          ...(lstModulo.length !== 0 ? { strModulos: lstModulo.join(',') } : {})
        },
        responseType: 'blob',
      });

      saveAs(response.data, idReporte.value === 1 ? `${'reporteModulo'}.${extension}` : idReporte.value === 2 ? `${'reporteRol'}.${extension}` : idReporte.value === 3 ? `${'reporteIndividual'}.${extension}` : ``);
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const getUsuarios = async () => {
    const data = await axiosConfig({
      method: 'get',
      url: `/usuarios/usuariosByRole/Coordinador`,
      params: {
      }
    });

    if (data) {
      let option = [];
      for (let i = 0; i < data.rows.length; i++) {
        let objAux = {};
        objAux = {
          value: data.rows[i].user_id,
          label: data.rows[i].name
        }
        option.push(objAux);
      }
      setUsuarios(option);
    }
  };

  const getRoles = async () => {
    const data = await axiosConfig({
      method: 'get',
      url: `/roles`,
      params: {
      }
    });

    if (data) {
      let option = [];
      for (let i = 0; i < data.rows.length; i++) {
        let objAux = {};
        objAux = {
          value: data.rows[i].name,
          label: data.rows[i].name
        }
        option.push(objAux);
      }
      setRoles(option);
    }
  };

  const getCategories = async () => {
    const data = await axiosConfig({
      method: 'get',
      url: `/categories`,
      params: {
      }
    });

    if (data) {
      let option = [];
      for (let i = 0; i < data.rows.length; i++) {
        let objAux = {};
        objAux = {
          value: data.rows[i].id_category,
          label: data.rows[i].name
        }
        option.push(objAux);
      }
      setCategorias(option);
    }
  };

  const getRegiones = async () => {
    const data = await axiosConfig({
      method: 'get',
      url: `/regions`,
      params: {
      }
    });

    if (data) {
      let option = [];
      for (let i = 0; i < data.rows.length; i++) {
        let objAux = {};
        objAux = {
          value: data.rows[i].region_id,
          label: data.rows[i].name
        }
        option.push(objAux);
      }
      setRegiones(option);
    }
  };

  const getUsuariosRoles = async () => {
    const data = await axiosConfig({
      method: 'get',
      url: `/reportes/usuariosReporteIndividual`,
      params: {
      }
    });

    if (data) {
      let option = [];
      for (let i = 0; i < data.users.length; i++) {
        let objAux = {};
        objAux = {
          value: data.users[i].user_id,
          label: data.users[i].name
        }
        option.push(objAux);
      }
      setUsuariosRoles(option);
    }

  };

  const getModulos = async () => {
    const data = await axiosConfig({
      method: 'get',
      url: `/reportes/modulosFilters`,
      params: {
        fechaInicio: fechaInicio,
        fechaFinal: fechaFin,
        strCategories: lstCategorias.join(',')
      }
    });

    if (data) {
      let option = [];
      for (let i = 0; i < data.modulos.length; i++) {
        let objAux = {};
        objAux = {
          value: data.modulos[i].id_goal_config,
          label: data.modulos[i].name
        }
        option.push(objAux);
      }
      setModulos(option);
    }

  };


  const handleChangeIdReporte = (target) => {
    setIdUsuario('');
    setIdCoordinador('');
    setLstCategorias([]);
    setLstModulo([]);
    setRegion([]);
    setNombreRol('')
    setIdReporte({ label: target.label, value: target.value });
  }

  const handleChangeIdCoordinador = (target) => {
    setIdCoordinador({ label: target.label, value: target.value });
  }

  const handleChangeNombreRol = (target) => {
    console.log("nombreRol: ", target.value)
    setNombreRol({ label: target.label, value: target.value });
  }

  const handleChangeRegion = (target) => {
    let auxRegion = [];
    for (let i = 0; i < target.length; i++) {
      auxRegion.push(target[i].value);
    }
    console.log("auxRegion: ", auxRegion.join(','))
    setRegion(auxRegion);
  }

  const handleChangeIdUsuario = (target) => {
    setIdUsuario({ label: target.label, value: target.value });
  }

  const handleChangeLstCategories = (target) => {
    //console.log("target: ", target)
    let auxCategories = [];
    for (let i = 0; i < target.length; i++) {
      auxCategories.push(target[i].value);
    }
    console.log("auxCategories: ", auxCategories.join(','))
    setLstCategorias(auxCategories);
    /*if(fechaInicio !== '' && fechaFin !== '' && auxCategories.length !== 0){
      setLstCategorias(auxCategories);
      getModulos();
    }*/
  }

  const handleChangeModulos = (target) => {
    //console.log("target: ", target)
    let auxModulos = [];
    for (let i = 0; i < target.length; i++) {
      auxModulos.push(target[i].value);
    }
    console.log("auxModulos: ", auxModulos.join(','))
    setLstModulo(auxModulos);
  }

  const handleChangeFechaInicio = async (fechaInicial) => {

    setFechaInicio(fechaInicial);
    /*if(fechaInicio !== '' && fechaFin !== '' && lstCategorias.length !== 0){
      setFechaInicio(fechaInicial);
      getModulos();
    }*/
  }

  const handleChangeFechaFin = async (fechaFinal) => {
    setFechaFin(fechaFinal);
    /*if(fechaInicio !== '' && fechaFin !== '' && lstCategorias.length !== 0){
      setFechaFin(fechaFinal);
      getModulos();
    }*/
  }

  useEffect(() => {
    getModulos(); // This is be executed when `loading` state changes
  }, [lstCategorias, fechaFin, fechaInicio])

  useEffect(() => {
    getUsuarios()
    getUsuariosRoles();
    getRoles();
    getRegiones();
    getCategories();

  }, []);

  return (
    <div>
      <div className='pl-6 '>
        <div className=' w-11/12 pb-3'>
          <div className='titulo  text-3xl'>Reportes</div>
        </div>
        <div className='Login w-11/12 bg-white shadow rounded'>
          <div className='rojodiv w-full h-6 rounded-t'></div>

          <div className='tablamenu p-8'>
            <div className='flex flex-row'>
              <div className='w-1/4 pr-4'>
                <div className='font-black pb-2'>Elegir tipo de reporte*</div>
                <Select
                  className='rounded w-full'
                  noOptionsMessage={() => 'No hay trabajadores.'}
                  value={idReporte}
                  onChange={(value) => handleChangeIdReporte(value)}
                  options={[{ label: 'Reporte por módulo', value: 1 }, { label: 'Reporte por rol', value: 2 }, { label: 'Reporte individual', value: 3 }, { label: 'Reporte de visitas', value: 4 }]}
                />
              </div>
              <div className='w-1/4 pr-4'>
                <div className='font-black pb-2'>Elegir fecha inicio*</div>
                <input
                  type='month'
                  id='start_date'
                  name='start_date'
                  value={fechaInicio}
                  onChange={(e) => handleChangeFechaInicio(e.target.value)}
                  className='w-11/12 h-8 border-2  rounded '
                  style={{ minHeight: '38px' }}
                />
              </div>
              <div className='w-1/4 pr-4'>
                <div className='font-black pb-2 '>Elegir fecha final*</div>
                <input
                  type='month'
                  id='start_date'
                  name='start_date'
                  value={fechaFin}
                  onChange={(e) => handleChangeFechaFin(e.target.value)}
                  className='w-11/12 h-8 border-2  rounded'
                  style={{ minHeight: '38px' }}
                />
              </div>
              <div className='w-1/4 pr-4'>
                <div className='font-black'></div>
                <div className='pt-8'>
                  <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={downloadReport}>
                    Descargar reporte
                  </button>
                </div>
              </div>
            </div>
            {(idReporte.value === 1 || idReporte.value === 4) &&
              <div className='flex flex-row pt-4'>
                {idReporte.value === 4 &&
                  <div className='w-1/4 pr-4'>
                    <div className='font-black pb-2'>Elegir rol</div>
                    <Select
                      className='rounded w-full'
                      noOptionsMessage={() => 'No hay roles.'}
                      value={nombreRol}
                      onChange={(value) => handleChangeNombreRol(value)}
                      options={roles}
                    />
                  </div>}
                <div className='w-1/4 pr-4'>
                  <div className='font-black pb-2'>Elegir categorías</div>
                  <Select
                    className='rounded w-full'
                    noOptionsMessage={() => 'No hay usuarios.'}
                    isMulti
                    closeMenuOnSelect={false}
                    onChange={(value) => handleChangeLstCategories(value)}
                    options={categorias}
                  />
                </div>
                {fechaInicio !== '' && fechaFin !== '' && lstCategorias.length > 0 && <div className='w-1/4 pr-4'>
                  <div className='font-black pb-2'>Elegir modulo</div>
                  <Select
                    className='rounded w-full'
                    noOptionsMessage={() => 'No hay modulos.'}
                    isMulti
                    closeMenuOnSelect={false}
                    onChange={(value) => handleChangeModulos(value)}
                    options={modulos}
                  />
                </div>}
              </div>}
            {/*idReporte.value === 2 &&
            <div className='flex flex-row pt-4'>
                <div className='w-1/4 pr-4'>
                    <div className='font-black pb-2'>Elegir coordinador</div>
                    <Select 
                        className='rounded w-full'
                        noOptionsMessage={() => 'No hay coordinadores.'}
                        value={idCoordinador}
                        onChange={(value) => handleChangeIdCoordinador(value)} 
                        options={usuarios}
                      />
                </div>
            </div>*/}

            {idReporte.value === 2 &&
              <div className='flex flex-row pt-4'>
                {/*<div className='w-1/4 pr-4'>
                  <div className='font-black pb-2'>Elegir rol*</div>
                  <Select
                    className='rounded w-full'
                    noOptionsMessage={() => 'No hay roles.'}
                    value={nombreRol}
                    onChange={(value) => handleChangeNombreRol(value)}
                    options={roles}
                  />
                </div>*/}
                <div className='w-1/4 pr-4'>
                  <div className='font-black pb-2'>Elegir región</div>
                  <Select
                    className='rounded w-full'
                    noOptionsMessage={() => 'No hay regiones.'}
                    onChange={(value) => handleChangeRegion(value)}
                    options={[...regiones]}
                    isMulti
                    closeMenuOnSelect={false}
                  />
                </div>
              </div>}
            {(idReporte.value === 3 || idReporte.value === 4) &&
              <div className='flex flex-row pt-4'>
                <div className='w-1/4 pr-4'>
                  <div className='font-black pb-2'>Elegir usuario</div>
                  <Select
                    className='rounded w-full'
                    noOptionsMessage={() => 'No hay usuarios.'}
                    value={idUsuario}
                    onChange={(value) => handleChangeIdUsuario(value)}
                    options={usuariosRoles}
                  />
                </div>
              </div>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Reportes;
