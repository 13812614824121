import React, { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai'

import axiosConfig from '../../utils/axiosConfig';

const DetalleMetaComponent = () => {
   const { id: idMeta } = useParams();
   const [ detalleMetas, setDetalleMetas ] = useState( {} );

   const getDetalleMeta = useCallback(  async () => {
      const data = await axiosConfig({
         method: 'get',
         url: `/goalsConfiguration/${ idMeta }`,
      } );
      
      if( data ) {
         console.log( data )
         setDetalleMetas( data );
      }
   } , [ idMeta ] )

   useEffect(() => {
      getDetalleMeta();
   }, [ getDetalleMeta ]);

   return (
      <div className=' pl-6 pt-4 pb-6'>
      <div className='Login w-11/12 bg-white shadow rounded'>
        <div className='rojodiv w-full h-4 rounded-t'></div>
        <div className='flex flex-row border-b-2 pl-6 pt-3 pb-3 '>
          <Link to={`/inicio/configMetas`}>
            <div className='pr-4'>
              <AiOutlineArrowLeft size={24} />
            </div>
          </Link>
          <div className='titulo text-xl'> Detalle de configuración de meta </div>
        </div>
         <div className='p-4'>
            <div className='tablamenu p-6 flex flex-row'>
               <div className='w-1/3'>
                  <div className='font-black'>Nombre de la configuración de la meta</div>
                  <p> {detalleMetas.name} </p>
               </div>
               
               <div className='w-1/3'>
                  <div className='font-black'>Categoría</div>
                  <p> {detalleMetas.Category &&  detalleMetas.Category.name} </p>
               </div>
               
               <div className='w-1/3'>
                  <div className='font-black'>Formulario</div>
                  <p> {detalleMetas.Form &&  detalleMetas.Form.name} </p>
               </div>
               
            </div>
            <div className='tablamenu p-6 flex flex-row'>
               
            <div className='w-1/3'>
                  <div className='font-black'>Puesto asignado a la meta</div>
                  <p> {detalleMetas.Role && detalleMetas.Role.name} </p>
               </div>

               <div className='w-1/3'>
                  <div className='font-black'>Segmento de negocio</div>
                  <p> {detalleMetas.Business && detalleMetas.Business.name} </p>
               </div>

               <div className='w-1/3'>
                  <div className='font-black'>Meta</div>
                  <p> {detalleMetas.goal } </p>
               </div>

            </div>
        </div>
      </div>
      <div className='Login w-11/12 bg-white shadow rounded mt-5'>
        <div className='rojodiv w-full h-4 rounded-t'></div>
        <div className='flex flex-row border-b-2 titulo pl-6 pt-3 pb-3 text-xl'>
          Submetas de configuración de meta
        </div>
        
        <div className='p-4'>
          <div style={{ overflow: "auto" }}>
            <table className='content-table w-full'>
              <thead>
                <tr>
                 <th>Día inicio</th>
                 <th>Día final</th>
                 <th>Objetivo de la submeta</th>
                </tr>
              </thead>
              <tbody>
              { detalleMetas.Sub_goals_config && detalleMetas.Sub_goals_config.map( ( subGoal, index ) => {
                  return (
                    <tr key={ index } >
                        <td>{ subGoal.first_day } </td>
                        <td>{ subGoal.last_day } </td>
                        <td>{ subGoal.goal } </td>
                    </tr>
                  );
                }) }
              </tbody>
            </table>
          </div>
        </div>  
               
            
      </div>
    </div>
  );
};

export default DetalleMetaComponent;
