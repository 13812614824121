import { Route, Switch, useRouteMatch } from 'react-router-dom';
import SolicitudVacaciones from '../components/SolicitudVacaciones/solicitudVacaciones';

export const SolicitudVacacionesRoute = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${url}/`}
        component={SolicitudVacaciones}
      />
    </Switch>
  );
};
