import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { NotificationManager } from 'react-notifications';

import { FaPlus } from 'react-icons/fa';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { MdArrowBack } from 'react-icons/md';

import { useFormik } from 'formik';

import axiosConfig from '../../utils/axiosConfig';

const init = {
   name: '',
   category: '',
   business: '',
   rol: '',
   form: '',
   goal: '',
};

const CreateMeta = () => {
   const [category, setCategory] = useState([]);
   const [formularios, setFormularios] = useState([]);
   const [business, setBusiness] = useState([]);
   const [rols, setRols] = useState([])

   const history = useHistory();

   const [tempSubGoal, setTempSubGoal] = useState([]);

   const diasMes = useMemo(() => [], [])

   const getBusiness = useCallback(async () => {
      const { rows } = await axiosConfig({
         method: 'get',
         url: `/business`,
         params: {
            limit: 1000,
            page: 1,
         },
      });

      if (rows && rows.length > 0) {
         setBusiness(rows)
      }
   }, [])

   const getRols = useCallback(async () => {
      const { rows } = await axiosConfig({
         method: 'GET',
         url: '/roles',
         params: {
            limit: 1000,
            page: 1
         }
      });

      if (rows && rows.length > 0) {
         setRols(rows)
      }
   }, [])

   const getForm = useCallback(async () => {
      const { rows } = await axiosConfig({
         method: 'get',
         url: `/forms`,
         params: {
            limit: 1000,
            page: 1,
         },
      });

      if (rows && rows.length > 0) {
         setFormularios(rows);
      }
   }, [])

   const getCategorys = useCallback(async () => {
      const { rows } = await axiosConfig({
         method: 'get',
         url: `categories`,
         params: {
            limit: 1000,
            page: 1,
         },
      });

      if (rows && rows.length > 0) {
         setCategory(rows);
      }
   }, [])

   const calculardias = useCallback(() => {
      //  const today = new Date();
      //  const numDias = new Date(
      //    today.getFullYear(),
      //    today.getMonth() + 1,
      //    0,
      //  ).getDate();

      const numDias = 32;
      for (let index = 1; index < numDias; index++) {
         diasMes.push({ index });
      }

   }, [diasMes])

   const submit = (data) => {
      axiosConfig({
         method: 'POST',
         url: '/goalsConfiguration',
         data: { ...data },
      }).then(() => {
         NotificationManager.success('Configuración Creada con éxito', 'Guardado', 2500);
         history.push("/inicio/configMetas");
      }).catch(() => {
         NotificationManager.error('No se pudo completar la creación de esta configuración', 'Error', 2500);
      });

   };

   const validTempSubGoal = (tam) => {
      if (tempSubGoal.length === 0) {
         return "No hay sub-metas establecidas"
      }
      if (tempSubGoal[0].first_day !== 1) {
         return "El primer día de la primera sub-meta debe ser 1"
      }
      let aux = tempSubGoal[0]
      let counter = tempSubGoal[0].goal
      let flag = false
      for (let i = 1; i < tempSubGoal.length; i++) {
         const goalConfig = tempSubGoal[i];

         if (aux.last_day === goalConfig.first_day - 1) {
            aux = goalConfig
            counter = counter + goalConfig.goal
            flag = false
            continue;
         } else {
            flag = true
            break;
         }
      }

      if (flag) {
         return "El Primer día del sucesor debe de ser una diferencia de un día"
      }

      /*const last = tempSubGoal.length - 1
      if( tempSubGoal[ last ].last_day !== diasMes[ diasMes.length - 1 ].index ){
         return `El ultimo día de la ultima sub-meta debe ser ${ diasMes[ diasMes.length - 1 ].index }`
      }*/


      /*if( counter !== tam ){
         return "La suma de las Sub-metas debe de ser igual al objetivo mensual "
      }*/

      return ""
   }

   const formik = useFormik({
      initialValues: { ...init },
      onSubmit: (values) => {
         const data = {
            name: values.name,
            id_category: Number(values.category),
            id_form: Number(values.form),
            id_business: Number(values.business),
            rol_id: Number(values.rol),
            goal: Number(values.goal),
            sub_goals: tempSubGoal
         }
         submit(data)
      },
      validate: (values) => {
         const error = {}

         if (values.name === "") error.name = "Falta el nombre de la Configuración"
         if (values.category === "") error.category = "Falta seleccionar categoría"
         if (values.form === "") error.form = "Falta seleccionar el formulario"
         if (values.business === "") error.business = "Falta seleccionar el tipo de negocio"
         if (values.rol === "") error.rol = "Falta seleccionar el tipo de negocio"
         if (values.goal === "" ||
            values.goal === 0) error.goal = "Falta establecer el numero de metas"

         const ErroAux = validTempSubGoal(Number(values.goal))
         if (ErroAux !== "") error.subGoals = ErroAux

         return error

      }
   })

   const remove = (index) => {
      setTempSubGoal(s => s.filter((_, i) => i !== index))
   }

   const push = () => {
      setTempSubGoal(s => [...s, {
         first_day: 0,
         last_day: 0,
         goal: 0
      }])
   }

   const handleChangeTemp = ({ target }, index) => {

      const id = target.id
      const op = id.split('.')

      switch (op[2]) {
         case 'goal':
            const value = target.value
            const sAux = tempSubGoal[index]
            sAux.goal = Number(value)

            setTempSubGoal(s => [...s])
            break;
         case 'fechaInicio':
            const valueI = target.value
            const sAuxI = tempSubGoal[index]
            sAuxI.first_day = Number(valueI)

            setTempSubGoal(s => [...s])
            break;
         case 'fechaFinal':

            const valueF = target.value
            const sAuxF = tempSubGoal[index]
            sAuxF.last_day = Number(valueF)

            setTempSubGoal(s => [...s])
            break;
         default:
            break;
      }
   }

   useEffect(() => {
      getForm();
      getCategorys();
      getBusiness();
      getRols()
      calculardias();
   }, [getForm, getCategorys, calculardias, getBusiness, getRols]);

   return (
      <div className='pl-6 pb-6'>

         <div className='w-full pb-3'>
            <div className='titulo  py-3 text-3xl'>Metas</div>
         </div>

         <div className='Login w-full bg-white shadow rounded'>
            <div className='rojodiv w-full h-6 rounded-t' />

            <div className='flex flex-row border-b-2 titulo p-3 text-xl'>
               <div className='mr-4'>
                  <Link to='/inicio/configMetas'>
                     <MdArrowBack size={24} />
                  </Link>
               </div>
               Asignar otra meta
            </div>

            <div className='tablamenu p-4'>
               <form onSubmit={formik.handleSubmit}>

                  <div>
                     <div className='flex flex-col'>

                        <div className='py-3 flex flex-row'>
                           <div className='w-1/4 mx-4'>
                              <label htmlFor="name">Nombre de meta</label>
                              <div>
                                 <input
                                    type='text'
                                    id='name'
                                    name='name'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                                    className='w-11/12  border border-gray-300 rounded	 pt-1 '
                                    placeholder='Ingrese nombre de la configuracion'
                                 />
                              </div>
                              {formik.touched.name && formik.errors.name &&
                                 <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                                    {formik.errors.name}
                                 </div>
                              }
                           </div>

                           <div className='w-1/4 mx-4'>
                              <label htmlFor={`category`}>Categoría</label>
                              <div>
                                 <select
                                    name="category"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.category}
                                    className='w-11/12 border-2 border-gray-300 rounded'
                                 >
                                    <option value="" >-</option>
                                    {category.map((category, index) => (
                                       <option key={index} value={category.id_category} >
                                          {category.name}
                                       </option>)
                                    )}
                                 </select>
                              </div>
                              {formik.touched.category && formik.errors.category &&
                                 <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                                    {formik.errors.category}
                                 </div>
                              }
                           </div>

                           <div className='w-1/4 mx-4'>
                              <label htmlFor={`form`}>Elegir formulario</label>
                              <div>
                                 <select
                                    name="form"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.form}
                                    className='w-11/12 border-2 border-gray-300 rounded'
                                 >
                                    <option value="">-</option>
                                    {formularios.map((formularios, index) => (
                                       <option key={index} value={formularios.id_form} >
                                          {formularios.name}
                                       </option>
                                    )
                                    )}
                                 </select>
                              </div>
                              {formik.touched.form && formik.errors.form &&
                                 <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                                    {formik.errors.form}
                                 </div>
                              }
                           </div>
                        </div>

                        <div className='py-3 flex flex-row'>

                           <div className='w-1/4 mx-4'>
                              <label htmlFor="rol"> Rol </label>
                              <select
                                 id="rol"
                                 name='rol'
                                 onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                                 value={formik.values.rol}
                                 className='w-11/12 border-2 border-gray-300 rounded'
                              >
                                 <option value="" >-</option>
                                 {
                                    rols.map((r, index) => (
                                       <option key={index} value={r.rol_id}> {r.name} </option>
                                    ))
                                 }
                              </select>
                           </div>

                           <div className='w-1/4 mx-4'>
                              <label htmlFor={`business`}> Segmento de negocio </label>
                              <select
                                 name='business'
                                 id='business'
                                 onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                                 value={formik.values.business}
                                 className='w-11/12 border-2 border-gray-300 rounded'
                              >
                                 <option value="" >-</option>
                                 {
                                    business.map((b, index) => (
                                       <option key={index} value={b.id_business}> {b.name} </option>
                                    ))
                                 }
                              </select>
                           </div>


                           <div className='w-1/4 mx-4'>
                              <label htmlFor={`goal`}>Objetivo mensual</label>
                              <input
                                 type="number"
                                 name="goal"
                                 id='goal'
                                 min="1"
                                 onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                                 value={formik.values.goal}
                                 className='w-11/12  border border-gray-300 rounded	 pt-1 '
                                 placeholder='Ingrese nombre de la configuracion'
                              />
                              {formik.touched.goal && formik.errors.goal &&
                                 <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                                    {formik.errors.goal}
                                 </div>
                              }
                           </div>

                        </div>

                     </div>
                  </div>

                  <div className='titulo border-b pt-4 pb-1 mb-4'> Sub-objetivos </div>
                  <div>
                     {tempSubGoal.map((subgoal, index) => (
                        <div className='my-6  flex flex-row' key={index}>

                           <div className='col mx-5'>
                              <label className='mr-5' htmlFor={`subGoals.${index}.fechaInicio`}>
                                 Dia de inicio:
                              </label>
                              <select className='w-6/12' value={subgoal.first_day} onChange={(e) => handleChangeTemp(e, index)} id={`subGoals.${index}.fechaInicio`} >
                                 <option value=""> - </option>
                                 {
                                    diasMes.map((d, index) => (
                                       <option key={index}> {d.index} </option>
                                    ))
                                 }
                              </select>
                           </div>

                           <div className='col mx-5'>
                              <label className='mr-5' htmlFor={`subGoals.${index}.fechaFinal`} >
                                 Dia de Final:
                              </label>
                              <select className='w-6/12' value={subgoal.last_day} onChange={(e) => handleChangeTemp(e, index)} id={`subGoals.${index}.fechaFinal`} >
                                 <option value=""> - </option>
                                 {
                                    diasMes.map((d, index) => (
                                       <option key={index}> {d.index} </option>
                                    ))
                                 }
                              </select>
                           </div>

                           <div className='col mx-5'>
                              <label className='mr-5' htmlFor={`subGoals.${index}.goal`} >
                                 Sub-meta:
                              </label>
                              <div className='w-6/12'>
                                 <input id={`subGoals.${index}.goal`} type="number" min="1" value={subgoal.goal} onChange={(e) => handleChangeTemp(e, index)} />
                              </div>
                           </div>

                           <div className='col mx-5'>
                              <button type='button' className='secondary' onClick={() => remove(index)} >
                                 <div className='text-red-700 py-5'>
                                    <RiDeleteBin6Fill size={25} />
                                 </div>
                              </button>
                           </div>

                        </div>
                     ))
                     }
                     {formik.submitCount > 0 && formik.errors.subGoals &&
                        <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                           {formik.errors.subGoals}
                        </div>
                     }
                     <button type='button' className='verdeletra flex flex-row font-bold py-2' onClick={push} >
                        <div className='pt-1 pr-1'>
                           <FaPlus />
                        </div>
                        Agregar sub-objetivo
                     </button>

                  </div>

                  <div className='grid justify-items-center  pt-7'>
                     <button className='botonverde  w-44  rounded shadow' type='submit' >
                        Guardar Meta
                     </button>
                  </div>

               </form>
            </div>
         </div>
      </div>
   );
};

export default CreateMeta;
