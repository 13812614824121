import React, { useEffect, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { BiEdit } from 'react-icons/bi';
import axiosConfig from '../../utils/axiosConfig';

const FormularioDetalle = () => {
  const { id: idFdetalle } = useParams();

  const [fdetalle, setFdetalle] = useState({});

  const getFdetalles = async () => {
    const data = await axiosConfig({
      method: 'get',
      url: `forms/${idFdetalle}`,
    });

    if (data) {
      setFdetalle(data);
    }
  };
  useEffect(() => {
    getFdetalles();

    // eslint-disable-next-line
  }, [idFdetalle]);

  return (
    <div className='pl-6 pt-8 w-full'>
      <div className='flex flex-row justify-between  pb-3'>
        <div className='titulo  text-3xl'>Formularios</div>
        <Link to={`/inicio/formularios/ediatformulario/${ idFdetalle }`} >
          <button className='botonverde py-1 flex flex-row w-24 h-10 rounded  shadow'>
            <div className='pt-1 px-2'>
              <BiEdit />
            </div>
            <div>Editar</div>
          </button>
        </Link>
      </div>
      <div className='Login  bg-white shadow rounded'>
        <div className='rojodiv w-full h-6 rounded-t'></div>
        <div className='flex flex-row border-b-2 titulo p-4 text-xl'>
          <Link to='/inicio/formularios'>
            <div className='pt-1 pr-1'>
              <MdArrowBack size={24} />
            </div>
          </Link>
          Detalles de formulario
        </div>
        <div className='tablamenu p-6 flex flex-row'>
          <div className='w-1/3'>
            <div className='font-black'>Nombre de formulario</div>
            <p>{fdetalle.name} </p>
          </div>
          <div className='w-2/3'>
            <div className='font-black'>URL</div>
            <a className='url text-lg' target="_blank" rel="noreferrer" href={fdetalle.url}>
              Visitar
            </a>

          </div>
        </div>
      </div>
    </div>
  );
};
export default FormularioDetalle;
