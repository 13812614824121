import React, { useEffect, useCallback } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import { NotificationManager } from 'react-notifications';

import { MdArrowBack } from 'react-icons/md';

import { useFormik } from 'formik';

import axiosConfig from '../../utils/axiosConfig';

const FormularioEditar = () => {
   const history = useHistory();
   const { id:idForm } = useParams()

   const formik = useFormik({
      initialValues: {
         name: '',
         url: '',
      },
      onSubmit: ( values ) => {
         axiosConfig({
            method: 'PUT',
            url: `/forms/${ idForm }`,
            data: {
               num_form: 1,
               name: values.name,
               url: values.url,
               numPaginas: 1,
            },
         } ).then( () => {
            NotificationManager.success( 'Formulario Actualizado con éxito', 'Guardado',  2500 );
            history.push( `/inicio/formularios` );
         } ).catch( () =>  NotificationManager.error( 'No se pudo completar la actualización de este formulario', 'Error',  2500 ) )
      
      },
      validate: ( values ) => {
         const error = {}
         if( values.name === "" ) error.name = "Falta el nombre del formulario"
         if( values.url === "" )  error.url  = "Falta la url del formulario"
         return error
      }
   } );

   const getForm = useCallback( async () => {
      const data = await axiosConfig({
         method: 'GET',
         url: `/forms/${ idForm }`
      } );
       
      if( data ){
         formik.setValues( data )
      }
   }, [ idForm ] )

   useEffect( () => {
      getForm()
   }, [ getForm ] )

   return (
      <div className='pl-6 w-full '>
         <div className=' pb-3'>
            <div className='titulo  text-3xl'>Formularios</div>
         </div>

         <div className='Login  bg-white shadow rounded'>
            <div className='rojodiv w-full h-6 rounded-t'></div>
            <div className='flex flex-row border-b-2 titulo pl-6 pt-5 pb-3 text-xl'>
               <Link to='/inicio/formularios'>
                  <div className='pt-1 pr-1'>
                     <MdArrowBack size={24} />
                  </div>
               </Link>
               Editar nuevo formulario
            </div>
            <form onSubmit={ formik.handleSubmit }>
               <div className='tablamenu p-6 flex flex-row'>
                  <div className='w-1/3'>
                     <div className='font-black'>Nombre de formulario</div>

                     <input
                        type='text'
                        id='name'
                        name='name'
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                        className='w-11/12 border border-gray-300 rounded	 pt-1 '
                        placeholder='Nombre de formulario'
                     />
                     { formik.touched.name && formik.errors.name && 
                        <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                           { formik.errors.name }
                        </div>
                     }
                     
                  </div>

                  <div className='w-full'>
                     <div className='font-black'>URL</div>
                     <input
                        type='text'
                        id='url'
                        name='url'
                        onChange={formik.handleChange}
                        value={formik.values.url}
                        className='w-60 w-full border border-gray-300 rounded	 pt-1 '
                        placeholder='ingrese URL'
                     />
                     { formik.touched.url && formik.errors.url && 
                        <div className='flex flex-row text-lg font-bold m-2 text-red-700'>
                           { formik.errors.url }
                        </div>
                     } 
                  </div>
               </div>

               <div className='grid justify-items-center pb-4 pt-7'>
                  <button
                     type='submit'
                     className='botonverde w-64 rounded shadow'
                  >
                     Guardar formulario
                  </button>
               </div>
            </form>
         </div>
      </div>
   );
};

export default FormularioEditar;
