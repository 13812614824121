import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

import Swal from 'sweetalert2';
import axiosConfig from '../../utils/axiosConfig';

const SolicitudVacaciones = () => {
  const { id: idVdetalle } = useParams();

  const [vdetalle, setVdetalle] = useState({});
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');

  const getFdetalles = async () => {
    const data = await axiosConfig({
      method: 'get',
      url: `holidays/${idVdetalle}`,
    });

    if (data) {
      setVdetalle(data);
    }
  };

  const handleChangeFechaInicio = async (fechaInicio) => {
    console.log("fechaInicio: ", fechaInicio)
    setFechaInicio(fechaInicio)
  }

  const handleChangeFechaFin = async (fechaFin) => {
    console.log("fechaFin: ", fechaFin)
    setFechaFin(fechaFin)
  }

  useEffect(() => {
    getFdetalles();

    // eslint-disable-next-line
  }, [idVdetalle]);

  function solicitarVacaciones() {
    Swal.fire({
      icon: 'warning',
      title: 'Aviso',
      text: 'Está a punto de solicitar estas vacaciones esto no puede cambiarse. ¿Solicitar vacaciones?',
      showDenyButton: true,

      denyButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',

      confirmButtonColor: '#2F80ED',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axiosConfig({
          method: 'POST',
          url: `vacations/`,
          data: {
            start_date: fechaInicio,
            final_date: fechaFin
          }
        }).then( async () => {
          Swal.fire('Vacaciones solicitadas', '', 'success');
          setFechaInicio('');
          setFechaFin('');
      } ).catch(() => {
        Swal.fire('Ya existe una solicitud activa de este usuario', '', 'error');
     });
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info');
      }
    });
  }

  return (
    <div>
      <div className=' grid justify-items-center pt-10'>
        <div className='Login w-11/12 bg-white shadow rounded'>
          <div className='rojodiv w-full h-6 rounded-t'></div>
          <div className='flex flex-row border-b-2 titulo pl-6 pt-5 pb-3 text-xl'>
            Solicitud de vacaciones
          </div>

          <div className='tablamenu p-4'>
            <div className='flex flex-row'>
              <div className='w-1/5 p-5'>
                <div className='font-black'>Fecha inicio</div>
                <input
                      type='date'
                      id='start_date'
                      name='start_date'
                      value={fechaInicio}
                      onChange={(e) => handleChangeFechaInicio(e.target.value)}
                      className='w-11/12 h-8 border-2  rounded'
                    />
              </div>
              <div className='w-1/5 p-5'>
                <div className='font-black'>Fecha final</div>
                <input
                      type='date'
                      id='start_date'
                      name='start_date'
                      value={fechaFin}
                      onChange={(e) => handleChangeFechaFin(e.target.value)}
                      className='w-11/12 h-8 border-2  rounded'
                    />
              </div>
              <div className='w-1/5 p-5'>
                <div className='font-black'></div>
                <div className='pt-5'>
                  <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={solicitarVacaciones}>
                    Solicitar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolicitudVacaciones;
