import React, { useEffect, useState } from 'react';
import MenuItem from './Menuitem';
import { CgMenuBoxed } from 'react-icons/cg';

export const menuItems = [
  {
    name: 'Dashboard',
    exact: true,
    to: `/inicio/dashboard`,
    iconClassName: 'bi bi-speedometer2',
  },
  {
    name: 'Visitas',
    exact: true,
    to: `/inicio/visitas`,
    iconClassName: 'bi bi-clipboard-check',
  },
  {
    name: 'Vacaciones',
    exact: true,
    to: `/inicio/vacaciones`,
    iconClassName: 'bi bi-brightness-alt-high-fill',
  },
  {
    name: 'Solicitud de vacaciones',
    exact: true,
    to: `/inicio/solicitudVacaciones`,
    iconClassName: 'bi bi-calendar-week',
  },
  {
    name: 'Reportes',
    exact: true,
    to: `/inicio/reportes`,
    iconClassName: 'bi bi-file-earmark-check-fill',
  },
];

const SideMenu = (props) => {
  const [inactive, setInactive] = useState(false);
  useEffect(() => {
    if (inactive) {
      removeActiveClassFromSubMenu();
    }

    props.onCollapse(inactive);
  }, [inactive, props]);

  //just an improvment and it is not recorded in video :(
  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll('.sub-menu').forEach((el) => {
      el.classList.remove('active');
    });
  };

  /*just a little improvement over click function of menuItem
    Now no need to use expand state variable in MenuItem component
  */
  useEffect(() => {
    let menuItems = document.querySelectorAll('.menu-item');
    menuItems.forEach((el) => {
      el.addEventListener('click', (e) => {
        const next = el.nextElementSibling;
        removeActiveClassFromSubMenu();
        menuItems.forEach((el) => el.classList.remove('active'));
        el.classList.toggle('active');
        console.log(next);
        if (next !== null) {
          next.classList.toggle('active');
        }
      });
    });
  }, []);

  return (
    <div className={`side-menu ${inactive ? 'inactive' : ''}`}>
      <div className='top-section'>
        <div onClick={() => setInactive(!inactive)} className='toggle-menu-btn'>
          {inactive ? (
            <div>
              <div className='grid justify-items-center py-2'>
                <CgMenuBoxed size={26} />
              </div>
            </div>
          ) : (
            <div>
              <div className='flex flex-row py-2'>
                <div className='text-white tituloSB text-2xl py-4 px-1'>
                  Gestion Drive
                </div>
                <div className='pt-5'>
                  <CgMenuBoxed size={26} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='divider'></div>
      <div className='pb-5'></div>
      <div className='main-menu'>
        <ul>
          {menuItems.map((menuItem, index) => (
            <MenuItem
              key={index}
              name={menuItem.name}
              exact={menuItem.exact}
              to={menuItem.to}
              subMenus={menuItem.subMenus || []}
              iconClassName={menuItem.iconClassName}
              onClick={(e) => {
                if (inactive) {
                  setInactive(false);
                }
              }}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
