import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import FormularioDash from '../components/Formularios/formularioDash';
import FormularioDetalle from '../components/Formularios/formularioDetalles';
import FormularioNuevo from '../components/Formularios/formularioNuevo';
import FormularioEditar from '../components/Formularios/formularioEditar';


const FormulariosRoute = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={ `${ url }/detallesformulario/:id`}  component={ FormularioDetalle } />

      <Route exact path={ `${ url }/ediatformulario/:id` } component={ FormularioEditar } />

      <Route exact path={ `${ url }/nuevoformulario` } component={ FormularioNuevo } />
      
      <Route exact path={ `${ url }/` } component={ FormularioDash } />
    </Switch>
  );
};

export default FormulariosRoute;
