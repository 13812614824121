import React, { useCallback, useEffect, useState } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';

import { BiRefresh } from 'react-icons/bi';
import { FaSearch, FaToggleOff, FaToggleOn } from 'react-icons/fa';
import { BsCardList, BsArrowLeftShort, BsArrowRightShort, } from 'react-icons/bs';
import axiosConfig from '../../utils/axiosConfig';


const Usuariostabla = () => {
   const { url } = useRouteMatch();
   const [ rowsPerPage, setRowsPerPage ] = useState( 10 )
   const [ roles, setRoles ] = useState( [] );
   const [ rol, setRol ] = useState('');
   const [ search, setSearch ] = useState('');
   const [currentPage, setCurrentPage ] = useState(1);
   const [pageNumbers, setPageNumbers] = useState([]);
   const [usuarios, setUsuarios] = useState( [] );
   const [totalRows, setTotalRows] = useState();

   const getUsuarios = useCallback( async () => {
      if(rol !== ''){
         const  { count, rows }  = await axiosConfig({
            method: 'get',
            url: `/usuarios/usuariosByRole/`+rol,
            params: {
               limit: rowsPerPage,
               page: currentPage,
               ...(search ? { search: search } : {}),
             }
         });

         if( rows && rows.length > 0 ){
            if (count > 0) {
               setTotalRows(count);
             }
            setUsuarios( rows )
         }else{
            setTotalRows(0);
            setUsuarios([]);
         }
      }else{
         const  { count, rows }  = await axiosConfig({
            method: 'get',
            url: `/usuarios`,
            params: {
               limit: rowsPerPage,
               page: currentPage,
               ...(search !== '' ? { search: search } : {}),
             }
         });

         if( rows && rows.length > 0 ){
            if (count > 0) {
               setTotalRows(count);
             }
            setUsuarios( rows )
         }else{
            setTotalRows(0);
            setUsuarios([]);
         }
      }
   }, [ rowsPerPage, currentPage, rol, search ] )

   const handleChangeSearch = (evt) => {
      const searchValue = evt.target.value;
      setSearch(searchValue);
      console.log("valor de search: ", search)
    };


   const getRoles = async () => {
      const { rows } = await axiosConfig({
        method: 'get',
        url: `roles`,
      } );
      
      if (rows) {
        setRoles(rows);
      }  
   }

   const handleChangeRol = (rol) => {
      setRol(rol);
      setCurrentPage(1);
   }

   useEffect(() => {
      getUsuarios();
      getRoles();
   }, [ getUsuarios,rol ]);

   useEffect(() => {
      setCurrentPage(1);
    }, [rowsPerPage]);

   useEffect(() => {
      const auxPageNumbers = [];
      for (let i = 1; i <= Math.ceil(totalRows / rowsPerPage); i++) {
        auxPageNumbers.push(i);
      }
      console.log("pageNumber: ", pageNumbers)
      setPageNumbers(auxPageNumbers);
    }, [totalRows]);

    const toggleActive = useCallback( async ( idUsuario, role ) => {
      console.log("id: ", idUsuario)
      console.log("rol: ", role)
      setRol(role);
      const data = await axiosConfig({
         method: 'PUT',
         url: `/usuarios/activo/${ idUsuario }`
      } );
  
      if( data ){
         const  { count, rows }  = await axiosConfig({
            method: 'get',
            url: `/usuarios/usuariosByRole/`+role,
            params: {
               limit: rowsPerPage,
               page: currentPage,
               // search: search,
             }
         });
   
         if( rows && rows.length > 0 ){
            if (count > 0) {
               setTotalRows(count);
             }
            setUsuarios( rows )
         }else{
            setTotalRows(0);
            setUsuarios([]);
         }
      }
   }, [ ] );

   return (
      <div className='py-6 px-2'>
         <div className='flex flex-row justify-between w-full pb-3'>
            <div className='titulo  text-3xl'>Usuarios</div>
         </div>

         <div className='Login w-full bg-white shadow rounded'>
            <div className='rojodiv w-full h-6 rounded-t'></div>
            <div className='w-full'>
        
               <div className='p-8'>
                  <div className='flex flex-row justify-between'>
                     <div className='flex flex-row '>
                        <div className='flex flex-row'>
                           <div className='tablamenu pr-2'>Numero de registros</div>
                           <div className='flex flex-row'>
                              <select
                                 value={ rowsPerPage }
                                 onChange={ ( { target } ) => setRowsPerPage( parseInt( target.value ) )}
                                 className='border-gray-400 border rounded '
                              >
                                 <option value='10'>10</option>
                                 <option value='25'>25</option>
                                 <option value='50'>50</option>
                                 <option value='100'>100</option>
                              </select>
                           </div>
                           
                        </div>
                     </div>
                     <div className='w-1/5 flex flex-row'>
                        <div className='pr-5'>Rol</div>
                           <select
                              value={rol}
                              onChange={(event) => handleChangeRol(event.target.value)}
                              className='w-full  border border-gray-300 rounded-sm '
                           >
                              <option value={""}>-</option>
                              { roles && roles.map((rol, index) => {
                              return (
                                 <option key={ index } value={rol.name}>
                                    {rol.name}
                                 </option>
                              );
                              })}
                           </select>
                        </div>
                        
                     <div className='flex flex-row '>
                  
                        <div className='w-2'></div>
                     
                        <button className='tablamenu  bg-gray-100 border-2 rounded'>
                           <BiRefresh size={24} />
                        </button>
                        
                        <div className='w-2'></div>
                        
                        <button className='tablamenu p-1 bg-gray-100 border-2 rounded-l' onClick={() => getUsuarios()}>
                           <FaSearch />
                        </button>

                        <input
                           type='text'
                           className='w-60  border-1  pt-1 border focus:border-blue-500 rounded-r'
                           placeholder='Buscar Usuario'
                           value={search}
                           onChange={evt => handleChangeSearch(evt)}
                        />
                     </div>
                  </div>

                  <table className='content-table w-full'>
                     <thead>
                        { usuarios.length > 0 &&
                           <tr>
                              <th>Nombre</th>
                              <th>Rol</th>
                              <th>Opciones</th>
                           </tr>
                        }
                     </thead>
                     <tbody>
                        {  usuarios.length === 0 && <tr><td className='text-xl font-medium'> No se encontraron usuarios con este rol, por favor seleccione un rol válido. </td></tr> }
                        {  usuarios.map( ( usuario, index ) => (
                           <tr key={ index } >
                              <td>{usuario.name}</td>
                              <td>{usuario.rol}</td>
                              <td>
                              <div className="flex flex-row justify-center">
                                 <Link to={`${url}/detalles/${ usuario.user_id }`}>
                                    <button className='btn-sm botonazuldetalles flex flex-row' >
                                    <div className='pt-1 px-1'>
                                       <BsCardList />
                                    </div>
                                    <div>Detalles</div>
                                    </button>
                                 </Link>
                                 { 
                                    <button
                                          type="button"
                                          className={`flex flex-row mx-3 text-4xl ${ usuario.active ? "text-green-500" : "text-gray-700" } `}
                                          title="Habilitar / Inhabilitar"
                                          onClick={ () => toggleActive( usuario.user_id, usuario.rol) }
                                    >
                                    <div className='pt-1 px-1 	'>
                                          { usuario.active ?  <FaToggleOn /> : <FaToggleOff /> }
                                    </div>
                                    </button>
                                 }
                              </div>
                              </td>
                           </tr>
                        ) ) }
                     </tbody>
                  </table>
               </div>
            </div>
            <div className='flex flex-row-reverse mt-3'>
            {/* Rows per page select */}

            {/* Page navigator */}
            <div className='inline-flex pb-5 pr-10'>
              <button
                onClick={() => {
                  setCurrentPage((prev) => {
                    return prev - 1;
                  });
                }}
                disabled={currentPage === 1 ? 'disabled' : ''}
                className={`btn-primary bg-blue-500 text-white py-1 px-2 
								rounded-l focus:outline-none text-xl ${
                  currentPage === 1
                    ? 'opacity-50 cursor-not-allowed'
                    : 'opacity-75 hover:opacity-100'
                }`}
              >
                <BsArrowLeftShort />
              </button>
              <select
                value={currentPage}
                onChange={(event) =>
                  setCurrentPage(parseInt(event.target.value))
                }
                className='btn-primary bg-blue-500 text-white py-1 px-2 focus:outline-none'
              >
                {pageNumbers.map((p) => {
                  return (
                    <option key={p} value={p}>
                      {p}
                    </option>
                  );
                })}
              </select>
              <button
                onClick={() => {
                  setCurrentPage((prev) => {
                    return prev + 1;
                  });
                }}
                disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
                className={`btn-primary bg-blue-500 text-white py-1 px-2 rounded-r focus:outline-none text-xl ${
                  currentPage === pageNumbers.length
                    ? 'opacity-50 cursor-not-allowed'
                    : 'opacity-75 hover:opacity-100'
                }`}
              >
                <BsArrowRightShort />
              </button>
            </div>
          </div>
         </div>
      </div>
   );
};

export default Usuariostabla;
