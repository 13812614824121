import { Route, Switch, useRouteMatch } from 'react-router-dom';
import VacacionesDash from '../components/Vacaciones/VacacionesDash';
import VacacionesDetalles from '../components/Vacaciones/VacacionesDeatlles';

export const VacacionesRoute = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      {/* <Redirect exact path={`${url}/`} to={`${url}/dashboardvacaciones`} /> */}
      <Route
        exact
        path={`${url}/detallesvacaciones/:id`}
        component={VacacionesDetalles}
      />
      <Route exact path={`${url}/`} component={VacacionesDash} />
    </Switch>
  );
};
