import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { MdArrowBack } from 'react-icons/md'

import PersonasAsignadas from '../personasasignadas';

import axiosConfig from '../../../utils/axiosConfig';

const InformacionUsuario = () => {
  const [openTab, setOpenTab] = React.useState(1);
  const { id: idFdetalle } = useParams();

  const [fdetalle, setFdetalle] = useState({});

  const getFdetalles = async () => {
    const data = await axiosConfig({
      method: 'get',
      url: `/usuarios/${idFdetalle}`,
    });

    if (data) {
      setFdetalle(data);
    }
  };

  useEffect( () => {
    getFdetalles();
  }, [idFdetalle]);

  return (
    <div className='pl-6 '>
      
      <div className='flex flex-row justify-between w-full pb-3'>
        <div className='titulo  text-3xl'>Usuarios</div>
      </div>
      <div className=' '>
        <div className='Login w-full bg-white shadow rounded'>
          <div className='rojodiv w-full h-6 rounded-t'></div>
          <div className='w-full'>
            <ul className='flex  flex-wrap pb-4 flex-row' role='tablist'>
              <li>
                <Link to='/inicio/usuario' >
                    <div className='text-lg px-4 border-b-2 pt-3 pb-4 block leading-normal'>
                        <MdArrowBack size={24} />
                    </div>
                </Link>  
              </li>
              <li>
                <a
                  className={
                    'text-xs px-5 py-3 border-b-2  block leading-normal  ' +
                    (openTab === 1
                      ? ' text-lg	 border-b-4 bg-white TabsActive'
                      : 'rojoletra text-lg	 bg-white Tabs 	')
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle='tab'
                  href='#link1'
                  role='tablist'
                >
                  
                  Información
                </a>
              </li>

              <li className='-mb-px mr-2 last:mr-0 flex-auto text-center'>
                <span
                  className={
                    'text-xs px-5 py-3 border-b-2  block leading-normal ' +
                    (openTab === 99
                      ? ' text-lg	 border-b-4 bg-white TabsActive'
                      : 'text-white  text-lg	 bg-white Tabs 	')
                  }
                >
                  <div>.</div>
                </span>
              </li>
            </ul>

            <div className={openTab === 1 ? 'block' : 'hidden'} id='link1'>
              <div className=' flex flex-row p-8'>
                <div className='w-1/6'>
                  <div className='font-black'>Nombre</div>
                  {fdetalle.name}
                </div>
                <div className='w-1/6'>
                  <div className='font-black'>Rol</div>
                  {fdetalle.rol}
                </div>
              </div>
            </div>
            <div className={openTab === 2 ? 'block' : 'hidden'} id='link2'>
              <PersonasAsignadas />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformacionUsuario;
