import React from 'react';
import { useHistory } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { logout } from '../utils/authService';
import { FaUserCircle } from 'react-icons/fa';
import { BsBellFill } from 'react-icons/bs';

import logo from '../assets/images/logo.png';
import { getToken } from '../utils/authService';

import Dropdown from '@material-tailwind/react/Dropdown';

import DropdownLink from '@material-tailwind/react/DropdownLink';

const Topbar = ( { inactive } ) => {
  const token = getToken();
  const nombre = jwt.decode(token);
  const history = useHistory()

  return (
    <div className={ `Topbar flex flex-row justify-between bg-white h-14 w-full ${ inactive ? "pr-16" : "pr-60" }`}>
      <div className='p-2'>
        <img src={logo} alt='Logo' />
      </div>
      <div className='flex flex-row'>
        <div className='pt-6 pl-2 '>
          <FaUserCircle />
        </div>
        <div className='pt-6 pl-2'>
          <BsBellFill />
        </div>
        <div className='pt-5 px-2'>{nombre.fullname}</div>
        <div className='pt-5 '>
          <Dropdown
            color='#ffffff'
            placement='bottom-start'
            buttonType='link'
            size='sm'
            rounded={false}
            block={false}
            ripple='dark'
            buttonText=""
          >
            <DropdownLink
              href='#'
              color='gray'
              ripple='light'
              onClick={() => {
                logout();
                history.push('/login')
              }}
            >
              Cerrar Sesión
            </DropdownLink>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
