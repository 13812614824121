import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RegionesDash from '../components/Regiones/regionesDash';
import DetalleRegion from '../components/Regiones/detalleRegion';
import DetalleEstado from '../components/Regiones/detalleEstado';
import CrearEstado from '../components/Regiones/crearEstado';
import CrearRegion from '../components/Regiones/crearRegion';

export const RegionesRoutes = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${url}/`}
        component={RegionesDash}
      />
      <Route
        path={`${url}/detalleRegion/:id`}
        component={DetalleRegion}
      />
      <Route
        path={`${url}/detalleEstado/:id`}
        component={DetalleEstado}
      />
      <Route
        path={`${url}/crearEstado`}
        component={CrearEstado}
      />
      <Route
        path={`${url}/crearRegion`}
        component={CrearRegion}
      />
    </Switch>
  );
};
