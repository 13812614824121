import React from 'react';
import Detalles from './DetallesUsuario - pa/detalles';
import Periodo from './DetallesUsuario - pa/periodo';
import Supervision from './DetallesUsuario - pa/supervision';
import Clima from './DetallesUsuario - pa/clima';
import Reuniones from './DetallesUsuario - pa/reuniones';

const DetallesPA = () => {
  return (
    <div className=''>
      <div className='h-64'>
        <Detalles />
      </div>
      <div className='p-3'></div>
      <div className=' h-20 '>
        <Periodo />
      </div>
      <div className='p-3'></div>
      <div className=' h-52 flex flex-row'>
        <div className=' w-7/12'>
          <Supervision />
        </div>
        <div className=' w-5/12'>
          <Clima />
        </div>
      </div>
      <div className='p-6'></div>
      <div className=' h-40 w-7/12 '>
        <Reuniones />
      </div>
    </div>
  );
};

export default DetallesPA;
