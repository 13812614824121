import React, { useLayoutEffect, useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import Iframe from 'react-iframe';
import { MdArrowBack } from 'react-icons/md';

import axiosConfig from '../../utils/axiosConfig';

const VisitaformulariosD = () => {

   const { id: idForm } = useParams();   
   const [ nameForm, setNameForm ] = useState( "" );
   const [ url, setUrl ] = useState('');

   const getForm = useCallback( async () => {
      const { name,  url } = await axiosConfig({
         method: 'GET',
         url: `forms/${ idForm }`,
      } );

      if( url && name ){
         setUrl( url );
         setNameForm( name )
      }

   }, [ idForm ] )


   useLayoutEffect(() => {
      getForm();   
   }, [ getForm ] );

   return (
      <div className=' pl-3 pt-10 pb-8 '>
         <div className='Login w-full  bg-white shadow rounded'>
            <div className='rojodiv w-full h-6 rounded-t'></div>

            <div className='flex flex-row border-b-2 titulo pl-6 pt-5 pb-3 text-xl'>
               <div className='pt-1 pr-1'>
               <Link to={`/inicio/Visitas/listaformularios/${idForm}`}>
                  <div className='pt-1 pr-1'>
                     <MdArrowBack size={24} />
                  </div>
               </Link>
               </div>
               Volver al listado
            </div>

            <div className='p-4'>
               <div className='flex justify-center'>
                  <div className='flex flex-col justify-center'>
                     <div className='text-center titulo text-2xl'>
                        { nameForm }
                     </div>
                     <div className='my-4'>
                        Leer y seguir instrucciones para responder el formulario
                     </div>
                  </div>
               </div>

               <div className='iFrameWrap'>
                  {<Iframe
                     url={url+`?embedded=true`}
                     width='100%'
                     height='700px'
                     id={ idForm }
                     display='Ifram'
                     position='relative'
                  />}
   </div>

            </div>
         </div>
      </div>
   );
};

export default VisitaformulariosD;
