import React from 'react';

const Detalles = () => {
  return (
    <div>
      <div>
        <div className='flex flex-row justify-between  pb-2'>
          <div className='titulo  text-3xl'>Usuarios</div>
        </div>
      </div>
      <div className=' grid justify-items-center'>
        <div className='Login w-11/12 bg-white shadow rounded'>
          <div className='rojodiv w-full h-6 rounded-t'></div>
          <div className='border-b-2 titulo pl-6 pt-5 pb-3 text-xl'>
            Detalles de cordinador
          </div>

          <div className='p-8'>
            <div className='flex flex-row'>
              <div className='w-1/6'>
                <div>Nombre</div>
                ----
              </div>
              <div className='w-1/6'>
                <div>Supervisores asignados</div>
                ----
              </div>
              <div className='w-1/6'>
                <div>Administradores</div>
                ----
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detalles;
