import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from './routes/PrivateRoute';
// import './style/tailwind.css';
import Login from './components/login';
import '@material-tailwind/react/tailwind.css';
import Layout from './routes/layout.routes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Redirect exact from='/' to='login' />
      <Route path='/login' component={Login} />
      <PrivateRoute
        path='/inicio'
        component={Layout}
        allowed={[
          'Encargado Administrativo',
          'Administrador',
          'Supervisor de Operaciones Drive',
          'Coordinador',
          'Entrenador',
          'Encargado de integración',
          'Encargado de Calidad',
        ]}
      />
      {/* <Route path='/inicio' component={Layout} /> */}

      <Route render={() => <Redirect to='/' />} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
