import React, { useCallback, useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { AiOutlineArrowLeft, AiOutlineCloseSquare } from 'react-icons/ai'
import Modal from 'react-modal';
import axiosConfig from '../../utils/axiosConfig';
import { BsCardList } from 'react-icons/bs';
import ProgressBar from '../Progressbar/progress-bar.component';
import { FaEdit } from 'react-icons/fa';
import { RiSave3Fill } from 'react-icons/ri';
import Swal from 'sweetalert2';
import { NotificationManager } from 'react-notifications';

Modal.setAppElement('#root');
const estiloModal = {
  content: {
    top: '310px',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '70%',
    transform: 'translate(-50%, -50%)',
  },
}

const tableNames = ['Fecha inicio', 'Fecha final', 'Objetivo de submeta', 'Porcentaje completado', 'Acciones'];

const DetalleMeta = () => {
  const { id: idMeta, month, year } = useParams();
  const [detalleMetas, setDetalleMetas] = useState([]);
  const [openModal, setOpenModal] = useState(false)
  const [subgoals, setSubgoals] = useState([]);
  const [bandera, setBandera] = useState(false)

  console.log("month: ", month, "year: ", year)

  const navigate = useHistory();

  const getDetalleMeta = useCallback(async () => {
    const rows = await axiosConfig({
      method: 'get',
      url: `/goals/${idMeta}`,
      params: {
        month: month,
        year: year,
      },
    });

    if (rows) {
      setDetalleMetas(rows);
    }
  }, [idMeta, month, year]);

  useEffect(() => {
    getDetalleMeta();
  }, [getDetalleMeta]);

  const handleModal = (subgoals) => {
    setOpenModal(s => !s);
    getDetalleMeta();

    setSubgoals(subgoals);
  }

  const handleBandera = (evt, id) => {
    setBandera(s => !s);

    const newItems = subgoals.map(item => {
      if (item.id_sub_goal !== id) {
        return { ...item };
      }

      return { ...item, active: !item.active };

    });
    setSubgoals(newItems);
  }

  const handleChangeSubgoal = (evt, id) => {
    const { name, value } = evt.target;

    const newItems = subgoals.map(item => {
      if (item.id_sub_goal !== id) {
        return { ...item };
      }

      return { ...item, minimum_percentage: value };
    });

    setSubgoals(newItems);
  };

  const guardarCambioRow = async (subgoal) => {
    Swal.fire({
      title: '¿Estás seguro que quieres guardar?',
      showDenyButton: true,
      confirmButtonText: 'Guardar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosConfig({
          method: 'PUT',
          url: `/goals/subgoal/` + subgoal.id_sub_goal,
          data: subgoal
        }).then(async () => {
          NotificationManager.success("", "Guardado con éxito.", 2500);
          setOpenModal(s => !s);
          await getDetalleMeta();
        }).catch(() => NotificationManager.success("Algo salió mal, contacte a un administrador.", "Oops", 2500))
      } else {
        if (result.isDenied) {

        }
      }
    })
  }

  return (
    <div className=' pl-6 pt-4 pb-6'>
      <div className='Login w-11/12 bg-white shadow rounded'>
        <div className='rojodiv w-full h-4 rounded-t'></div>
        <div className='flex flex-row border-b-2 pl-6 pt-3 pb-3 '>
          <Link onClick={() => navigate.goBack()}>
            <div className='pr-4'>
              <AiOutlineArrowLeft size={24} />
            </div>
          </Link>
          <div className='titulo text-xl'> Detalle de meta </div>
        </div>
        <div className='p-4'>
          <div className='tablamenu p-6 flex flex-row'>
            <div className='w-1/3'>
              <div className='font-black'>Nombre de la meta</div>
              <p> {detalleMetas.info && detalleMetas.info.name} </p>
            </div>
            <div className='w-1/3'>
              <div className='font-black'>Categoría</div>
              <p> {detalleMetas.info && detalleMetas.info.Category.name} </p>
            </div>
            <div className='w-1/3'>
              <div className='font-black'>Negocio</div>
              <p> {detalleMetas.info && detalleMetas.info.Business.name} </p>
            </div>
          </div>
          <div className='tablamenu p-6 flex flex-row'>
            <div className='w-1/3'>
              <div className='font-black'>Formulario</div>
              <p> {detalleMetas.info && detalleMetas.info.Form.name} </p>
            </div>
            <div className='w-1/3'>
              <div className='font-black'>Fecha inicio</div>
              <p> {detalleMetas.info && detalleMetas.info.fechaInicio} </p>
            </div>
            <div className='w-1/3'>
              <div className='font-black'>Fecha fin</div>
              <p> {detalleMetas.info && detalleMetas.info.fechaFinal} </p>
            </div>
          </div>
          <div className='tablamenu p-6 flex flex-row'>
            <div className='w-1/3'>
              <div className='font-black'>Rol asignado a la meta</div>
              <p> {detalleMetas.users && detalleMetas.users[0].User.rol} </p>
            </div>
          </div>
        </div>
      </div>
      <div className='Login w-11/12 bg-white shadow rounded mt-5'>
        <div className='rojodiv w-full h-4 rounded-t'></div>
        <div className='flex flex-row border-b-2 titulo pl-6 pt-3 pb-3 text-xl'>
          Usuarios
        </div>

        <div className='p-4'>
          <div style={{ overflow: "auto" }}>
            <table className='content-table w-full'>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Objetivo de la meta</th>
                  <th>Porcentaje avanzado</th>
                  <th>
                    Opciones
                  </th>
                </tr>
              </thead>
              <tbody>
                {detalleMetas.users && detalleMetas.users.map((usuario, index) => {
                  return (
                    <tr key={index}>
                      <td>{usuario.User.name}</td>
                      <td>{usuario.contadorCompletado + '/' + usuario.goal}</td>
                      <td><ProgressBar bgcolor={'#81BC00'} completed={(((usuario.contadorCompletado / usuario.goal) * 100) > 100) ? 100 : ((usuario.contadorCompletado / usuario.goal) * 100).toFixed(1)} /></td>
                      <td>
                        <div className="flex flex-row justify-center">
                          <button className='flex flex-row mx-3 botonazuldetalles' onClick={() => handleModal(usuario.sub_goals)}>
                            <div className='pt-1 px-1'>
                              <BsCardList />
                            </div>
                            <div>Ver submetas</div>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>


      </div>


      {<Modal isOpen={openModal} contentdiv='Usuario Modal' style={estiloModal} onRequestClose={() => handleModal()}>

        <div className='flex flex-row justify-between border-b-2 pb-2'>
          <div className='titulo text-xl'>
            Detalle de submeta
          </div>

          <button className='rojoletra' onClick={() => setOpenModal(false)}>
            <AiOutlineCloseSquare size={28} />
          </button>
        </div>

        <div className='w-full'>
          <div className='p-4'>
            <div style={{ overflow: "auto" }}>
              <table className='content-table w-full'>
                <thead>
                  <tr>
                    {tableNames.map((name) => {
                      return <th key={name}>{name}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {subgoals && subgoals.map((subgoal, index) => {
                    return (
                      <tr key={index}>
                        <td>{subgoal.fechaInicio}</td>
                        <td>{subgoal.fechaFinal}</td>
                        {/*Aquí usé el active como bandera porque se me hizo más fácil manejarlo así. */}
                        {subgoal.active && <td>{subgoal.contadorCompletado + '/' + subgoal.minimum_percentage}</td>}
                        {!subgoal.active && <td>
                          <input
                            type="number"
                            min="1"
                            value={subgoal.minimum_percentage}
                            onChange={evt => handleChangeSubgoal(evt, subgoal.id_sub_goal)}
                            className='w-1/4 pl-1 border border-gray-300 rounded	 pt-1 '
                          />
                        </td>}
                        {<td><ProgressBar bgcolor={'#81BC00'} completed={((subgoal.contadorCompletado / subgoal.minimum_percentage) * 100) > 100 ? 100 : ((subgoal.contadorCompletado / subgoal.minimum_percentage) * 100).toFixed(1)} /></td>}
                        {subgoal.active && (
                          <td>
                            <div className="flex flex-row justify-center">
                              <button className='flex flex-row mx-3 botonverdeeditar' onClick={evt => handleBandera(evt, subgoal.id_sub_goal)}>
                                <div className='pt-1 px-1'>
                                  <FaEdit />
                                </div>
                                <div>Editar</div>
                              </button>
                            </div>
                          </td>

                        )}
                        {!subgoal.active && (
                          <td>
                            <div className="flex flex-row justify-center">
                              <button className='flex flex-row mx-3 botonverdeeditar' onClick={() => guardarCambioRow(subgoal)}>
                                <div className='pt-1 px-1'>
                                  <RiSave3Fill className=' mx-auto text-2xl	' />
                                </div>
                                <div>Guardar</div>
                              </button>
                            </div>
                          </td>

                        )}
                      </tr>
                    );
                  })}

                </tbody>
              </table>
            </div>
          </div>
        </div>



      </Modal>}
    </div>
  );
};

export default DetalleMeta;
