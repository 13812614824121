import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Reportes from '../components/Reportes/reportes';
import Dashboard from '../components/Dashboard/dashboard';
import VisitasRoute from './visitas.routes';
import UsuarioRoute from './usuario.routes';
import { VacacionesRoute } from './vacaciones.routes';
import { SolicitudVacacionesRoute } from './solicitudVacaciones.routes';
import { RegionesRoutes } from './regiones.routes';
import FormulariosRoute from './formularios.routes';
import MetasRoute from './metas.routes';
import PrivateRoute from './PrivateRoute';
// import ModuloMetasDashboard from '../components/ModuloMetas/moduloMetasdashboard';
import ModuloMetasRoute from './moduloMetas.routes';
import jwt from 'jsonwebtoken';
import { getToken } from '../utils/authService';

export const Panel = () => {
  const { url } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Redirect exact path={`${url}/`} to={`${url}/dashboard`} />
        <Route exact path={`${url}/dashboard`} component={Dashboard} />
        <Route path={`${url}/visitas`} component={VisitasRoute} />
        <PrivateRoute path={`${url}/usuario`} component={UsuarioRoute} allowed={['Administrador']} />
        <PrivateRoute
          path={`${url}/configMetas`}
          component={MetasRoute}
          allowed={['Administrador']}
        />
        <PrivateRoute
          path={`${url}/metas`}
          component={ModuloMetasRoute}
          allowed={['Administrador']}
        />
        <PrivateRoute
          path={`${url}/vacaciones`}
          component={VacacionesRoute}
          allowed={[
            'Encargado Administrativo', 'Administrador', 'Supervisor de Operaciones Drive', 'Coordinador', 'Entrenador', 'Encargado de integración', 'Encargado de Calidad'
          ]}
        />
        <PrivateRoute
          path={`${url}/solicitudVacaciones`}
          component={SolicitudVacacionesRoute}
          allowed={[
            'Encargado Administrativo', 'Administrador', 'Supervisor de Operaciones Drive', 'Coordinador', 'Entrenador', 'Encargado de integración', 'Encargado de Calidad'
          ]}
        />
        <PrivateRoute
          path={`${url}/regiones`}
          component={RegionesRoutes}
          allowed={[
            'Administrador'
          ]}
        />
        <PrivateRoute
          path={`${url}/formularios`}
          component={FormulariosRoute}
          allowed={['Administrador']}
        />
        {/* <Route path={`${url}/metas`} component={MetasRoute} />
        <Route path={`${url}/vacaciones`} component={VacacionesRoute} />
        <Route path={`${url}/formularios`} component={FormulariosRoute} /> */}
        <Route exact path={`${url}/reportes`} component={Reportes} />
      </Switch>
    </div>
  );
};

export default Panel;
