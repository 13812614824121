import React, { useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import Swal from 'sweetalert2';
import axiosConfig from '../../utils/axiosConfig';
import { FaPlus } from 'react-icons/fa';
import { RiDeleteBin6Fill, RiSave3Fill } from 'react-icons/ri';

const CrearEstado = () => {
  const { id: idVdetalle } = useParams();
  const history = useHistory();
  const [vdetalle, setVdetalle] = useState({});

  const [nombre, setNombre] = useState('');
  const [nomenclatura, setNomenclatura] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [cities, setCities] = useState([]);

  const getFdetalles = async () => {
    const data = await axiosConfig({
      method: 'get',
      url: `holidays/${idVdetalle}`,
    });

    if (data) {
      setVdetalle(data);
    }
  };

  const remove = ( index ) => {
    setCities( s => s.filter( ( _, i ) => i !== index ) )
  }

  const push = () => {
    setCities(s => [ ...s, {
			nombre: ciudad
		} ] );
    setCiudad('');
  }

  const handleChangeCiudad = (evt) => {
    const name = evt.target.value;
    setCiudad(name);
  };

  const handleChangeNombre = async (nombre) => {
    console.log("nombre: ", nombre)
    setNombre(nombre)
  }

  const handleChangeNomenclatura = async (nomenclatura) => {
    console.log("nomenclatura: ", nomenclatura)
    setNomenclatura(nomenclatura)
  }

  useEffect(() => {
    getFdetalles();

    // eslint-disable-next-line
  }, [idVdetalle]);

  function crearEstado() {
    let objCiudades = [];
    cities.forEach(city => {
      objCiudades.push(city.nombre);
    });
    Swal.fire({
      icon: 'warning',
      title: 'Aviso',
      text: 'Está seguro que quiere crear el estado?',
      showDenyButton: true,

      denyButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',

      confirmButtonColor: '#2F80ED',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axiosConfig({
          method: 'POST',
          url: `/regions/state`,
          data: {
            name: nombre,
            nomenclature: nomenclatura,
            cities: objCiudades
          }
        }).then( async () => {
          Swal.fire('Estado creado', '', 'success');
          setNombre('');
          setNomenclatura('');
          history.push( `/inicio/regiones` );
      } ).catch(() => {
        Swal.fire('Se detectó un error, contactar con el administrador.', '', 'error');
     });
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info');
      }
    });
  }

  return (
    <div>
      <div className=' grid justify-items-center pt-10'>
        <div className='Login w-11/12 bg-white shadow rounded'>
        <div className='rojodiv w-full h-6 rounded-t'></div>
            <div className='w-full'>
              <ul className='flex  flex-wrap pb-4 flex-row' role='tablist'>
                <li>
                  <Link to='/inicio/regiones' >
                      <div className='text-lg px-4 border-b-2 pt-3 pb-4 block leading-normal'>
                          <MdArrowBack size={24} />
                      </div>
                  </Link>  
                </li>
                <li>
                  <a
                    className={
                      'text-xs px-5 py-3 border-b-2  block leading-normal  ' + ' text-lg	 border-b-4 bg-white TabsActive'
                    }
                    data-toggle='tab'
                    href='#link1'
                    role='tablist'
                  >
                    
                    Crear estado
                  </a>
                </li>
                <li className='-mb-px mr-2 last:mr-0 flex-auto text-center'>
                  <span
                    className={
                      'text-xs px-5 py-3 border-b-2  block leading-normal ' +  'text-white  text-lg	 bg-white Tabs 	'
                    }
                  >
                    <div>.</div>
                  </span>
                </li>
              </ul>
            </div>
            

          <div className='tablamenu p-4'>
            <div className='flex flex-row'>
              <div className='w-1/3 p-5'>
                <div className='font-black'>Nombre</div>
                <input
                      type='text'
                      id='start_date'
                      name='start_date'
                      value={nombre}
                      onChange={(e) => handleChangeNombre(e.target.value)}
                      className='w-11/12 h-8 border-2  rounded p-1'
                    />
              </div>
              <div className='w-1/3 p-5'>
                <div className='font-black'>Nomenclatura</div>
                <input
                      type='text'
                      id='start_date'
                      name='start_date'
                      value={nomenclatura}
                      onChange={(e) => handleChangeNomenclatura(e.target.value)}
                      className='w-11/12 h-8 border-2  rounded p-1'
                    />
              </div>

              

            </div>
            <div className='titulo border-b pt-4 pb-1 pl-5 mb-4'> Ciudades del estado </div>

                        <div className='my-6  flex flex-row pl-4'>
                            <div className='w-1/5 mr-5'>
                              <div className='font-black'>Nombre de la ciudad</div>
                              <input 
                                    type="text"
                                    value={ciudad}
                                    onChange={evt => handleChangeCiudad(evt)}
                                    className='w-full border border-gray-300 rounded p-1'
                                    placeholder='Ingrese el nombre de la ciudad'
                                />
                            </div>
                            <button type='button' className='botonverde flex flex-row py-2 pl-4 mb-4 mt-4' onClick={ push } >
                              <div className='pt-1 pr-1'>
                                  <FaPlus />
                              </div>
                              Agregar ciudad
                            </button>
                        </div>
                     {cities.length > 0 &&<table className='content-table w-full'>
                        <thead>
                            <tr>
                              <th>Nombre</th>
                            <th>
                              Opciones
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            {cities.map( ( city, index ) => (
                              <tr key={index}>
                                    <td>{city.nombre}</td>
                                    <td>
                                      <div className='col mx-5'>
                                          <button type='button'  className='secondary' onClick={() => remove( index ) } >
                                          <div className='text-red-700 py-5'>
                                            <RiDeleteBin6Fill size={25} />
                                          </div>
                                          </button>
                                      </div>
                                    </td>
                              </tr>
                                ) )
                            }
                        </tbody>
                     </table>}
                     {cities.length === 0 && <div className='w-full grid justify-items-center text-xl'> NO HAY CIUDADES SELECCIONADAS. </div>}
                      <div className='flex flex-row justify-center'>
                        <button type='button' className='botonverde flex flex-row py-2 pl-4 mb-4 mt-4 content-center' onClick={ crearEstado } >
                          <div className='pt-1 pr-1'>
                            <RiSave3Fill className=' mx-auto text-2xl'/>
                          </div>
                          Guardar
                        </button>
                      </div>
                      
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrearEstado;
