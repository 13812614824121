import React from 'react';

const Periodo = () => {
  return (
    <div>
      <div className='pl-16'>
        <div className='Login w-5/12 bg-white p-4 shadow rounded'>
          <div className='w-1/6'>
            <div className='font-semibold'>Elegir periodo</div>
            <input type='date' className='border-gray-300	' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Periodo;
