import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Visitadashboard from '../components/Visitas/visitasdashboard';

import VisitaformulariosD from '../components/Visitas/visitaformularioD';
import Visitaformularios from './../components/Visitas/visitaformularios';

const VisitasRoute = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${url}/`} component={Visitadashboard} />

      <Route
        exact
        path={`${url}/listaformularios/:id`}
        component={Visitaformularios}
      />
      <Route
        exact
        path={`${url}/formularios/:id`}
        component={VisitaformulariosD}
      />
    </Switch>
  );
};

export default VisitasRoute;
