import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BsArrowLeftShort, BsArrowRightShort, BsCardList } from 'react-icons/bs';
import axiosConfig from '../../utils/axiosConfig';

const ModuloMetasDashboard = () => {

  const tableNames = ['Nombre', 'Categoría', 'Negocio', 'Opciones'];
  const [metasPerPage, setMetasPerPage ] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [search, ] = useState('');
  const [metas, setMetas] = useState([]);
  const [periodo, setPeriodo] = useState( [] );
  const [roles, setRoles] = useState( [] );
  const [mes, setMes] = useState( '' );
  const [year, setYear] = useState( '' );
  const [rol, setRol] = useState( 0 );
  const [totalRows, setTotalRows] = useState();

  const getGoals = useCallback(  async () => {
    const {count, rows} = await axiosConfig({
       method: 'get',
       url: `/goals`,
       params: {
         month: mes,
         year: year,
         ...(rol ? { rol_id: rol } : {}),
         limit: metasPerPage,
         page: currentPage,
       }
    });
    
    console.log("rows.length: ", rows)
    //if(rows && rows.length > 0){
      if (count > 0) {
        setTotalRows(count);
      }
       setMetas(rows);
    //} 
  }, [ mes, year, rol, currentPage, metasPerPage ] );

  useEffect(() => {
    const auxPageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalRows / metasPerPage); i++) {
      auxPageNumbers.push(i);
    }
    setPageNumbers(auxPageNumbers);
  }, [totalRows]);


  const getPeriodos = async () => {
    const rows = await axiosConfig({
      method: 'get',
      url: `goals/period`,
    } );
    
    if (rows) {
      setPeriodo(rows);
      setMes(rows.months[0].key)
      setYear(rows.years[0].key)
    } 
  };

  const getRoles = async () => {
    const { rows } = await axiosConfig({
      method: 'get',
      url: `roles`,
    } );
    
    if (rows) {
      setRoles(rows);
    }
  };

  useEffect(() => {
    getPeriodos();
    getRoles();
    getGoals();
  }, []);


  return (
    <div className='pl-6 pb-8 pr-6'>
      <div className='flex flex-row justify-between w-full pb-3'>
        <div className='titulo  text-3xl'>Metas</div>
      </div>

      <div className='Login w-full bg-white shadow rounded'>
        <div className='rojodiv w-full h-6 rounded-t'></div>
        <div className='tablamenu p-8 flex flex-row'>
            <div className='w-1/4 pr-4'>
                <div className='font-black'>Periodo mes</div>
                <select
                  value={mes}
                  onChange={(event) => setMes(event.target.value)}
                  className='w-full  border border-gray-300 rounded-sm '
                >
                  <option value={""}>-</option>
                  { periodo.months && periodo.months.map((month, index) => {
                    return (
                      <option key={ index } value={month.key}>
                        {month.value}
                      </option>
                    );
                  })}
                </select>
            </div>
            <div className='w-1/4 pr-4'>
                <div className='font-black'>Periodo año</div>
                <select
                  value={year}
                  onChange={(event) => setYear(event.target.value)}
                  className='w-full  border border-gray-300 rounded-sm '
                >
                  <option value={""}>-</option>
                  { periodo.years && periodo.years.map((year, index) => {
                    return (
                      <option key={ index } value={year.key}>
                        {year.value}
                      </option>
                    );
                  })}
                </select>
            </div>
            <div className='w-1/4 pr-4'>
                <div className='font-black'>Rol</div>
                <select
                  value={rol}
                  onChange={(event) => setRol(event.target.value)}
                  className='w-full  border border-gray-300 rounded-sm '
                >
                  <option value={""}>-</option>
                  { roles && roles.map((rol, index) => {
                    return (
                      <option key={ index } value={rol.rol_id}>
                        {rol.name}
                      </option>
                    );
                  })}
                </select>
            </div>
            <div className='w-1/4'>
                <button type='button' className='botonazuldetalles py-1 w-48 rounded-sm shadow mt-5' style={{textAlign:"center" }}  onClick={ () => getGoals() } >
                  Aplicar Filtros
                </button>
            </div>
        </div>
        <div className='p-8'>
          
            {/*<div className='flex flex-row '>
              <button className=' px-1.5 bg-gray-100 border-gray-400 border rounded'>
                <FaEraser size={16} />
              </button>
              <div className='w-2'></div>
              <button
                className=' px-1 bg-gray-100 border-gray-400 border rounded'
                // onClick={(event) => {
                //   event.preventDefault();
                //   getDrives();
                // }}
              >
                <BiRefresh size={24} />
              </button>
            </div>*/}




          <div className='p-4'>
          <div style={{ overflow: "auto" }}>
            <table className='content-table w-full'>
              <thead>
              { metas.length === 0 && <tr /> }  
                {metas.length !== 0 && <tr>
                  { tableNames.map((name) => {
                    return <th key={name}>{name}</th>;
                  })}
                </tr>}
              </thead>
              <tbody>
              { metas.length === 0 && 
                <tr><td> 
                  <div className='w-full grid justify-items-center text-xl'> No se encontraron metas, seleccione un periodo y aplique filtros. </div>
                </td></tr>
              }
               { metas && metas.map( ( meta, index ) => {
                  return <tr key={index}>
                      <td> {meta.name} </td>
                      <td> {meta.Category.name} </td>
                      <td> {meta.Business.name} </td>
                      <td>
                      <div className="flex flex-row justify-center">
                        <Link to={`/inicio/metas/metadetalle/${ meta.id_goal_config }/${ meta.month }/${ meta.year }`}>
                          <button className='flex flex-row mx-3 botonazuldetalles'>
                            <div className='pt-1 px-1'>
                                <BsCardList />
                            </div>
                            <div>Detalles</div>
                          </button>
                        </Link>                        
                        </div>
                      </td>
                    </tr>
                })}
                
              </tbody>
            </table>
          </div>
        </div>
          <div className='flex flex-row-reverse mt-3'>
            {/* Rows per page select */}

            {/* Page navigator */}
            <div className='inline-flex'>
              <button
                onClick={() => {
                  setCurrentPage((prev) => {
                    return prev - 1;
                  });
                }}
                disabled={currentPage === 1 ? 'disabled' : ''}
                className={`btn-primary bg-blue-500 text-white py-1 px-2 
								rounded-l focus:outline-none text-xl ${
                  currentPage === 1
                    ? 'opacity-50 cursor-not-allowed'
                    : 'opacity-75 hover:opacity-100'
                }`}
              >
                <BsArrowLeftShort />
              </button>
              <select
                value={currentPage}
                onChange={(event) =>
                  setCurrentPage(parseInt(event.target.value))
                }
                className='btn-primary bg-blue-500 text-white py-1 px-2 focus:outline-none'
              >
                {pageNumbers.map((p) => {
                  return (
                    <option key={p} value={p}>
                      {p}
                    </option>
                  );
                })}
              </select>
              <button
                onClick={() => {
                  setCurrentPage((prev) => {
                    return prev + 1;
                  });
                }}
                disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
                className={`btn-primary bg-blue-500 text-white py-1 px-2 rounded-r focus:outline-none text-xl ${
                  currentPage === pageNumbers.length
                    ? 'opacity-50 cursor-not-allowed'
                    : 'opacity-75 hover:opacity-100'
                }`}
              >
                <BsArrowRightShort />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModuloMetasDashboard;
