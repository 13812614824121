import React, { useCallback, useEffect, useState } from 'react';
import { BiRefresh } from 'react-icons/bi';
import { FaSearch } from 'react-icons/fa';
import { BsCardList } from 'react-icons/bs';
import axiosConfig from '../../utils/axiosConfig';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const PersonasAsignadas = () => {
  const tableNames = ['Nombre', 'Plaza', 'Total general', 'Opciones'];
  const [formularioPerPage, setformularioPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [totalRows, setTotalRows] = useState();
  const [administrativo, setadministrativo] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setCurrentPage(1);
  }, [formularioPerPage, search]);

  const getadministrativo = useCallback(  async () => {
    const { count, rows } = await axiosConfig({
      method: 'get',
      url: `/usuarios`,
      params: {
        limit: formularioPerPage,
        page: currentPage,
        // search: search,
      },
    });

    if (rows) {
      if (count > 0) {
        setTotalRows(count);
      }
      setadministrativo(rows);
    }
  }, [ formularioPerPage, currentPage ] )

  useEffect(() => {
    getadministrativo();
  }, [ getadministrativo ] );

  useEffect(() => {
    const auxPageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalRows / 3); i++) {
      auxPageNumbers.push(i);
    }
    setPageNumbers(auxPageNumbers);
  }, [totalRows]);
  return (
    <div>
      <div className='p-8'>
        <div className='flex flex-row justify-between'>
          <div className='flex flex-row '>
            <div className='tablamenu pt-1 pr-2'>Numero de registros</div>

            <div className='flex flex-row'>
              <select
                value={formularioPerPage}
                onChange={(event) =>
                  setformularioPerPage(parseInt(event.target.value))
                }
                className='   border-gray-400 border rounded '
              >
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </select>
            </div>
          </div>

          <div className='flex flex-row '>
            <div className='w-2'></div>
            <button
              className=' px-1 bg-gray-100 border-gray-400 border rounded'
              // onClick={(event) => {
              //   event.preventDefault();
              //   getDrives();
              // }}
            >
              <BiRefresh size={24} />
            </button>
            <div className='w-2'></div>
            <button
              className='  px-2 bg-gray-300 border-gray-300 border-b-2 border-l-2 border-t-2 rounded-l'
              // onClick={(event) => {
              //   event.preventDefault();
              //   getDrives();
              // }}
            >
              <FaSearch />
            </button>

            <input
              className='w-60  border-2 border-gray-300 rounded-r	 pt-1 '
              placeholder='Buscar'
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
        </div>
        <table className='content-table w-full'>
          <thead>
            <tr>
              {tableNames.map((name) => {
                return <th key={name}>{name}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {administrativo.map((administrativo) => {
              return (
                <tr key={administrativo.user_id}>
                  <td>{administrativo.name}</td>
                  <td>{administrativo.address}</td>
                  <td>100%</td>
                  <td>
                    <button className=' botonazuldetalles   '>
                      <Link
                        to={`/inicio/usuario/detallesPersonasAsiganadas`}
                        className='w-16'
                      >
                        <div className='flex flex-row'>
                          <div className='pt-1 px-1'>
                            <BsCardList />
                          </div>
                          <div>Detalles</div>
                        </div>
                      </Link>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className='flex flex-row-reverse mt-3'>
          {/* Rows per page select */}

          {/* Page navigator */}
          <div className='inline-flex'>
            <button
              onClick={() => {
                setCurrentPage((prev) => {
                  return prev - 1;
                });
              }}
              disabled={currentPage === 1 ? 'disabled' : ''}
              className={`btn-primary bg-blue-500 text-white py-1 px-2 
								rounded-l focus:outline-none text-xl ${
                  currentPage === 1
                    ? 'opacity-50 cursor-not-allowed'
                    : 'opacity-75 hover:opacity-100'
                }`}
            >
              <BsArrowLeftShort />
            </button>
            <select
              value={currentPage}
              onChange={(event) => setCurrentPage(parseInt(event.target.value))}
              className='btn-primary bg-blue-500 text-white py-1 px-2 focus:outline-none'
            >
              {pageNumbers.map((p) => {
                return (
                  <option key={p} value={p}>
                    {p}
                  </option>
                );
              })}
            </select>
            <button
              onClick={() => {
                setCurrentPage((prev) => {
                  return prev + 1;
                });
              }}
              disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
              className={`btn-primary bg-blue-500 text-white py-1 px-2 rounded-r focus:outline-none text-xl ${
                currentPage === pageNumbers.length
                  ? 'opacity-50 cursor-not-allowed'
                  : 'opacity-75 hover:opacity-100'
              }`}
            >
              <BsArrowRightShort />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonasAsignadas;
