import React,{ useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router';
import { getVerifiedToken } from '../utils/authService';

const PrivateRoute = ({ component: Component, allowed, ...rest }) => {
  const [access, setAccess] = useState(undefined);

  useEffect(() => {
    function checkToken() {
      const verified = getVerifiedToken();
      if (verified) {
        setAccess(verified.decoded);
      } else {
        setAccess(null);
      }
    }
    checkToken();
  }, []);

  if (access === undefined) {
    // logout();
    return <></>;
  } else if (access == null || (access && !allowed.includes(access.role))) {
    // logout();
    return <Redirect to='/' />;
  } else if (access) {
    return <Route {...rest} component={Component} />;
  }
};
export default PrivateRoute;
