import React from 'react';

const Supervision = () => {
  return (
    <div>
      <div className=' grid justify-items-center'>
        <div className='Login w-10/12 bg-white shadow rounded'>
          <div className='verde w-full h-4 rounded-t'></div>
          <div className='border-b-2 titulo pl-6 pt-5 pb-3 text-xl'>
            Supervision
          </div>

          <div className='p-8'>
            <div className='flex flex-row'>
              <div className='w-1/3'>
                <div className='border-b-2 font-semibold'>
                  visita estructurada
                </div>
                <div className='flex flex-row pt-4'>
                  <div className='w-1/3'>
                    <div className='font-semibold'>Real</div>
                    ----
                  </div>
                  <div className='w-1/3'>
                    <div className='font-semibold'>Obj.</div>
                    ----
                  </div>
                  <div className='w-1/3'>
                    <div className='font-semibold'>Cal</div>
                    <div></div>
                    ----
                  </div>
                </div>
              </div>
              <div className='w-1/3'>
                <div className='border-b-2 font-semibold'>
                  semaforo por COVID
                </div>
                <div className='flex flex-row pt-4'>
                  <div className='w-1/3'>
                    <div className='font-semibold'>Real</div>
                    ----
                  </div>
                  <div className='w-1/3'>
                    <div className='font-semibold'>Obj.</div>
                    ----
                  </div>
                  <div className='w-1/3'>
                    <div className='font-semibold'>Cal</div>
                    <div></div>
                    ----
                  </div>
                </div>
              </div>

              <div className='w-1/3'>
                <div className='border-b-2 font-semibold'>
                  Semaforo de quejas
                </div>

                <div className='flex flex-row pt-4'>
                  <div className='w-1/3'>
                    <div className='font-semibold'>Real</div>
                    ----
                  </div>
                  <div className='w-1/3'>
                    <div className='font-semibold'>Obj.</div>
                    ----
                  </div>
                  <div className='w-1/3'>
                    <div className='font-semibold'>Cal</div>
                    <div></div>
                    ----
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Supervision;
