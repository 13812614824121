import React from 'react';

import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import axiosConfig from '../../../utils/axiosConfig';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';

const InformacionVacaciones = () => {
  const history = useHistory();
  const { id: idHolydays } = useParams();


  const formik = useFormik({
    initialValues: {
      start_date: '',
      final_date: '',
    },
    onSubmit: (values) => {
      axiosConfig({
        method: 'post',
        url: `/holidays`,
        data: {
          user: idHolydays,
          start_date: DateTime.fromISO(values.start_date).toFormat(
            "yyyy'/'LL'/'dd",
          ),
          final_date: DateTime.fromISO(values.final_date).toFormat(
            "yyyy'/'LL'/'dd",
          ),
        },
      });
      // alert('Solicitud de vacaciones añadida');
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Solicitud de vacaciones añadida',
        showConfirmButton: false,
        timer: 1500,
      });
      let path = `/inicio/vacaciones`;
      history.push(path);
    },
  });

  return (
    <div>
      <div className='pl-6'>
        <div className='Login w-full  bg-white shadow rounded'>
          <div className='verde w-full h-4 rounded-t'></div>
          <div className=' border-b-2 titulo pl-6 pt-5 pb-3 text-xl'>
            Vacaciones
          </div>
          <div className='tablamenu p-4'>
            <form onSubmit={formik.handleSubmit}>
              <div className='tablamenu p-4'>
                <div className='flex justify-between'>
                  <div className='w-1/3 flex flex-col'>
                    <div className='font-black'>Fecha de inicio</div>

                    <input
                      type='date'
                      id='start_date'
                      name='start_date'
                      value={formik.values.start_date}
                      onChange={formik.handleChange}
                      className='w-11/12 h-8 border-2  rounded'
                    />
                  </div>

                  <div className='w-1/3 flex flex-col'>
                    <div className='font-black'>Fecha de fin</div>
                    <input
                      type='date'
                      id='final_date'
                      name='final_date'
                      onChange={formik.handleChange}
                      value={formik.values.final_date}
                      className='w-11/12 h-8 border-2  rounded'
                    />
                  </div>
                </div>
              </div>
              <div className=' pb-4 pt-7'>
                <button
                  type='submit'
                  className='botonverde  w-44 rounded shadow'
                  // onClick={postFormulario(form, values)}
                >
                  Guardar nuevo formulario
                </button>
              </div>
            </form>
          </div>
          <div className='p-4'></div>
        </div>
      </div>
    </div>
  );
};
export default InformacionVacaciones;
