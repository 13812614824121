import { Route, Switch, useRouteMatch } from 'react-router-dom';

import ModuloMetasDashboard from '../components/ModuloMetas/moduloMetasdashboard';
import DetalleMeta from '../components/ModuloMetas/detalleMeta';

const ModuloMetasRoute = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${url}/`} component={ModuloMetasDashboard} />
      <Route path={`${url}/metadetalle/:id/:month/:year`} component={DetalleMeta} />
      {/* <Route path={`${url}/rformulario/:id`} component={VisitaformulariosD} /> */}
    </Switch>
  );
};

export default ModuloMetasRoute;
