import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Metadashboard from '../components/Metas/metasdashboard';

import DetalleMetaComponent from '../components/Metas/detalleMetaComponent';
import CreateMeta from '../components/Metas/CreateMeta';
import UpdateMeta from '../components/Metas/UpdateMeta';


const MetasRoute = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${url}/`} component={Metadashboard} />
      <Route exact path={ `${url}/createMeta` } component={ CreateMeta } />
      <Route path={`${url}/metasdetalles/:id`} component={DetalleMetaComponent} />
      <Route path={`${url}/update/:id`} component={ UpdateMeta } />
    </Switch>
  );
};

export default MetasRoute;
