import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import { BiRefresh } from 'react-icons/bi';
import { FaSearch, FaPlus, FaEraser } from 'react-icons/fa';
import Swal from 'sweetalert2';
import axiosConfig from '../../utils/axiosConfig';
import { BsCardList, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

const RegionesDash = () => {
  const { id: idVdetalle } = useParams();
  const [openTab, setOpenTab] = React.useState(1);
  const [vdetalle, setVdetalle] = useState({});
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');

  const [lstRegiones, setLstRegiones] = useState([]);
  const regionesTableNames = ['Nombre', 'Nomenclatura', 'Opciones'];
  const [regionesPerPage, setRegionesPerPage] = useState(10);
  const [regionesCurrentPage, setRegionesCurrentPage] = useState(1);
  const [regionesPageNumbers, setRegionesPageNumbers] = useState([]);
  const [regionesSearch, setRegionesSearch] = useState('');
  const [regionesTotalRows, setRegionesTotalRows] = useState();

  const [lstEstados, setLstEstados] = useState([]);
  const [estadosPerPage, setEstadosPerPage] = useState(10);
  const [estadosCurrentPage, setEstadosCurrentPage] = useState(1);
  const [estadosPageNumbers, setEstadosPageNumbers] = useState([]);
  const [estadosSearch, setEstadosSearch] = useState('');
  const [estadosTotalRows, setEstadosTotalRows] = useState();

  const getRegiones = useCallback(  async () => {
    const { count, rows } = await axiosConfig({
      method: 'get',
      url: `/regions`,
      params: {
        limit: regionesPerPage,
        page: regionesCurrentPage,
        // search: search,
      },
    });

    if (rows) {
      if (count > 0) {
        setRegionesTotalRows(count);
      }
      setLstRegiones(rows);
    }
  }, [ regionesPerPage, regionesCurrentPage ] )

  const getEstados = useCallback(  async () => {
    const { count, rows } = await axiosConfig({
      method: 'get',
      url: `/regions/state`,
      params: {
        limit: estadosPerPage,
        page: estadosCurrentPage,
        // search: search,
      },
    });

    if (rows) {
      if (count > 0) {
        setEstadosTotalRows(count);
      }
      setLstEstados(rows);
    }
  }, [ estadosPerPage, estadosCurrentPage ] )

  useEffect(() => {
      
    const auxPageNumbers = [];
    for (let i = 1; i <= Math.ceil(estadosTotalRows / estadosPerPage); i++) {
      auxPageNumbers.push(i);
    }
    setEstadosPageNumbers(auxPageNumbers);
  }, [estadosTotalRows]);

  useEffect(() => {
      
    const auxPageNumbers = [];
    for (let i = 1; i <= Math.ceil(regionesTotalRows / regionesPerPage); i++) {
      auxPageNumbers.push(i);
    }
    setRegionesPageNumbers(auxPageNumbers);
  }, [regionesTotalRows]);

  useEffect(() => {
    getEstados();
    getRegiones();
    // eslint-disable-next-line
  }, [idVdetalle]);

  const deleteRegion = async (idRegion) => {
      Swal.fire({
         title: '¿Estás seguro que quieres eliminarlo?',
         showDenyButton: true,
         confirmButtonText: 'Eliminar',
         denyButtonText: `Cancelar`,
       } ).then( ( result ) => {
         if( result.isConfirmed ){
            axiosConfig({
               method: 'PUT',
               url: `regions/delete/`+idRegion,
               data: {}
             }).then( async () => {
               NotificationManager.success( "", "Región eliminada", 2500 )   
             await getRegiones();
           } ).catch( () => NotificationManager.success( "Algo salió mal, contacte a un administrador.", "Oops", 2500 ) )
         } else {
           if( result.isDenied ) {
             
           }
         }
       } )
   }

   const deleteState = async (idEstado) => {
    Swal.fire({
       title: '¿Estás seguro que quieres eliminarlo?',
       showDenyButton: true,
       confirmButtonText: 'Eliminar',
       denyButtonText: `Cancelar`,
     } ).then( ( result ) => {
       if( result.isConfirmed ){
          axiosConfig({
             method: 'PUT',
             url: `regions/state/delete/`+idEstado,
             data: {}
           }).then( async () => {
             NotificationManager.success( "", "Estado eliminado", 2500 )   
           await getEstados();
         } ).catch( () => NotificationManager.success( "Algo salió mal, contacte a un administrador.", "Oops", 2500 ) )
       } else {
         if( result.isDenied ) {
           
         }
       }
     } )
 }

  return (
    <div>
      <div className=' grid justify-items-center pt-10'>
        <div className='Login w-11/12 bg-white shadow rounded'>
          <div className='rojodiv w-full h-6 rounded-t'></div>
          <div className='w-full'>
          <ul className='flex  flex-wrap pb-4 flex-row' role='tablist'>
              <li className=''>
                <a
                  className={
                    'text-xs px-5 py-3 border-b-2  block leading-normal  ' +
                    (openTab === 1
                      ? ' text-lg	 border-b-4 bg-white TabsActive'
                      : 'rojoletra text-lg	 bg-white Tabs 	')
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle='tab'
                  href='#link1'
                  role='tablist'
                >
                  Regiones
                </a>
              </li>
              <li className=''>
                <a
                  className={
                    'text-xs px-5 py-3 border-b-2  block leading-normal ' +
                    (openTab === 2
                      ? ' text-lg	 border-b-4 bg-white TabsActive'
                      : 'rojoletra text-lg	 bg-white Tabs 	')
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle='tab'
                  href='#link2'
                  role='tablist'
                >
                  Estados
                </a>
              </li>

              <li className='-mb-px mr-2 last:mr-0 flex-auto text-center'>
                <span
                  className={
                    'text-xs px-5 py-3 border-b-2  block leading-normal ' +
                    (openTab === 4
                      ? ' text-lg	 border-b-4 bg-white TabsActive'
                      : 'text-white  text-lg	 bg-white Tabs 	')
                  }
                >
                  <div>.</div>
                </span>
              </li>
            </ul>
          </div>



          <div className={openTab === 1 ? 'block' : 'hidden'} id='link1'>
            <div className='p-8'>
              <div className='flex flex-row justify-between w-full pb-4'>
                  <div className='titulo pb-6 text-3xl'></div>
                  <Link to={`/inicio/regiones/crearRegion/`}>
                      <button className='botonverde py-4 flex flex-row w-50 h-10 justify-items-center rounded-sm shadow'>
                        <div className='pt-1 px-2'>
                          <FaPlus />
                        </div>
                        <div>Agregar región</div>
                      </button>     
                  </Link>
              </div>
              <div className='flex flex-row justify-between'>
                <div className='flex flex-row '>
                  <div className='tablamenu pt-1 pr-2'>Numero de registros</div>

                  <div className='flex flex-row'>
                    <select
                      value={regionesPerPage}
                      onChange={(event) =>
                        setRegionesPerPage(parseInt(event.target.value))
                      }
                      className='   border-gray-400 border rounded '
                    >
                      <option value='10'>10</option>
                      <option value='25'>25</option>
                      <option value='50'>50</option>
                      <option value='100'>100</option>
                    </select>
                  </div>
                </div>

                <div className='flex flex-row '>
                  <div className='w-2'></div>
                  <button
                    className=' px-1 bg-gray-100 border-gray-400 border rounded'
                    // onClick={(event) => {
                    //   event.preventDefault();
                    //   getDrives();
                    // }}
                  >
                    <BiRefresh size={24} />
                  </button>
                  <div className='w-2'></div>
                  <button
                    className='  px-2 bg-gray-300 border-gray-300 border-b-2 border-l-2 border-t-2 rounded-l'
                    // onClick={(event) => {
                    //   event.preventDefault();
                    //   getDrives();
                    // }}
                  >
                    <FaSearch />
                  </button>

                  <input
                    className='w-60  border-2 border-gray-300 rounded-r	 pt-1 '
                    placeholder='Buscar'
                    value={regionesSearch}
                    onChange={(event) => setRegionesSearch(event.target.value)}
                  />
                </div>
              </div>
              {<table className='content-table w-full'>
                <thead>
                  <tr>
                    {regionesTableNames.map((name) => {
                      return <th key={name}>{name}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                {lstRegiones && lstRegiones.map((region) => {
                      return <tr>
                                <td>{region.name}</td>
                                <td>{region.nomenclature}</td>
                                <td>
                                <div className="flex flex-row justify-center">
                                  <button className=' botonazuldetalles   '>
                                    <Link
                                      to={`/inicio/regiones/detalleRegion/`+region.region_id}
                                      className='w-16'
                                    >
                                      <div className='flex flex-row'>
                                        <div className='pt-1 px-1'>
                                          <BsCardList />
                                        </div>
                                        <div>Ver región</div>
                                      </div>
                                    </Link>
                                  </button>
                                  <button className='flex flex-row mx-3 botonrojodetalles' onClick={() => deleteRegion(region.region_id) }>
                                    <div className='pt-1 px-1'>
                                        <FaEraser />
                                    </div>
                                    <div>Eliminar</div>
                                  </button> 
                                </div>
                                </td>
                              </tr>;
                    })}
                </tbody>
              </table>}
              <div className='flex flex-row-reverse mt-3'>
                <div className='inline-flex'>
                  <button
                    onClick={() => {
                      setRegionesCurrentPage((prev) => {
                        return prev - 1;
                      });
                    }}
                    disabled={regionesCurrentPage === 1 ? 'disabled' : ''}
                    className={`btn-primary bg-blue-500 text-white py-1 px-2 
                    rounded-l focus:outline-none text-xl ${
                      regionesCurrentPage === 1
                        ? 'opacity-50 cursor-not-allowed'
                        : 'opacity-75 hover:opacity-100'
                    }`}
                  >
                    <BsArrowLeftShort />
                  </button>
                  <select
                    value={regionesCurrentPage}
                    onChange={(event) =>
                      setRegionesCurrentPage(parseInt(event.target.value))
                    }
                    className='btn-primary bg-blue-500 text-white py-1 px-2 focus:outline-none'
                  >
                    {regionesPageNumbers.map((p) => {
                      return (
                        <option key={p} value={p}>
                          {p}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    onClick={() => {
                      setRegionesCurrentPage((prev) => {
                        return prev + 1;
                      });
                    }}
                    disabled={regionesCurrentPage === regionesPageNumbers.length ? 'disabled' : ''}
                    className={`btn-primary bg-blue-500 text-white py-1 px-2 rounded-r focus:outline-none text-xl ${
                      regionesCurrentPage === regionesPageNumbers.length
                        ? 'opacity-50 cursor-not-allowed'
                        : 'opacity-75 hover:opacity-100'
                    }`}
                  >
                    <BsArrowRightShort />
                  </button>
                </div>
              </div>
            </div>
          </div>






          <div className={openTab === 2 ? 'block' : 'hidden'} id='link1'>
            <div className='p-8'>
              <div className='flex flex-row justify-between w-full pb-4'>
                  <div className='titulo pb-6 text-3xl'></div>
                  <Link to={`/inicio/regiones/crearEstado/`}>
                      <button className='botonverde py-4 flex flex-row w-50 h-10 justify-items-center rounded-sm shadow'>
                        <div className='pt-1 px-2'>
                          <FaPlus />
                        </div>
                        <div>Agregar estado</div>
                      </button>     
                  </Link>
              </div>
              <div className='flex flex-row justify-between'>
                <div className='flex flex-row '>
                  <div className='tablamenu pt-1 pr-2'>Numero de registros</div>

                  <div className='flex flex-row'>
                    <select
                      value={regionesPerPage}
                      onChange={(event) =>
                        setRegionesPerPage(parseInt(event.target.value))
                      }
                      className='   border-gray-400 border rounded '
                    >
                      <option value='10'>10</option>
                      <option value='25'>25</option>
                      <option value='50'>50</option>
                      <option value='100'>100</option>
                    </select>
                  </div>
                </div>

                <div className='flex flex-row '>
                  <div className='w-2'></div>
                  <button
                    className=' px-1 bg-gray-100 border-gray-400 border rounded'
                    // onClick={(event) => {
                    //   event.preventDefault();
                    //   getDrives();
                    // }}
                  >
                    <BiRefresh size={24} />
                  </button>
                  <div className='w-2'></div>
                  <button
                    className='  px-2 bg-gray-300 border-gray-300 border-b-2 border-l-2 border-t-2 rounded-l'
                    // onClick={(event) => {
                    //   event.preventDefault();
                    //   getDrives();
                    // }}
                  >
                    <FaSearch />
                  </button>

                  <input
                    className='w-60  border-2 border-gray-300 rounded-r	 pt-1 '
                    placeholder='Buscar'
                    value={regionesSearch}
                    onChange={(event) => setRegionesSearch(event.target.value)}
                  />
                </div>
              </div>
              {<table className='content-table w-full'>
                <thead>
                  <tr>
                    {regionesTableNames.map((name) => {
                      return <th key={name}>{name}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                    {lstEstados && lstEstados.map((estado) => {
                      return <tr>
                                <td>{estado.name}</td>
                                <td>{estado.nomenclature}</td>
                                <td>
                                <div className="flex flex-row justify-center">
                                <button className=' botonazuldetalles   '>
                                  <Link
                                    to={`/inicio/regiones/detalleEstado/`+estado.state_id}
                                    className='w-16'
                                  >
                                    <div className='flex flex-row'>
                                      <div className='pt-1 px-1'>
                                        <BsCardList />
                                      </div>
                                      <div>Ver estado</div>
                                    </div>
                                  </Link>
                                </button>
                                  <button className='flex flex-row mx-3 botonrojodetalles' onClick={() => deleteState(estado.state_id) }>
                                    <div className='pt-1 px-1'>
                                        <FaEraser />
                                    </div>
                                    <div>Eliminar</div>
                                  </button> 
                                </div>
                                
                                </td>
                              </tr>;
                    })}
                      
                </tbody>
              </table>}
              <div className='flex flex-row-reverse mt-3'>
                <div className='inline-flex'>
                  <button
                    onClick={() => {
                      setRegionesCurrentPage((prev) => {
                        return prev - 1;
                      });
                    }}
                    disabled={regionesCurrentPage === 1 ? 'disabled' : ''}
                    className={`btn-primary bg-blue-500 text-white py-1 px-2 
                    rounded-l focus:outline-none text-xl ${
                      regionesCurrentPage === 1
                        ? 'opacity-50 cursor-not-allowed'
                        : 'opacity-75 hover:opacity-100'
                    }`}
                  >
                    <BsArrowLeftShort />
                  </button>
                  <select
                    value={regionesCurrentPage}
                    onChange={(event) =>
                      setRegionesCurrentPage(parseInt(event.target.value))
                    }
                    className='btn-primary bg-blue-500 text-white py-1 px-2 focus:outline-none'
                  >
                    {regionesPageNumbers.map((p) => {
                      return (
                        <option key={p} value={p}>
                          {p}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    onClick={() => {
                      setRegionesCurrentPage((prev) => {
                        return prev + 1;
                      });
                    }}
                    disabled={regionesCurrentPage === regionesPageNumbers.length ? 'disabled' : ''}
                    className={`btn-primary bg-blue-500 text-white py-1 px-2 rounded-r focus:outline-none text-xl ${
                      regionesCurrentPage === regionesPageNumbers.length
                        ? 'opacity-50 cursor-not-allowed'
                        : 'opacity-75 hover:opacity-100'
                    }`}
                  >
                    <BsArrowRightShort />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        
      </div>
    </div>
  );
};

export default RegionesDash;
